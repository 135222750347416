import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectedWorkorder } from "../../../redux/slices/workorderSlice";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

const CustomerWorkOrder = ({ setTimeReportList, customerId, setActiveTab }) => {
  const selectedWorkorderData = useSelector((state) => state.workorder.selectedWorkorder);
  const [selectedWorkOrderCustomerId, setSelectedWorkOrderCustomerId] = useState(selectedWorkorderData?.customer?._id);
  //Util
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allWorkorderData = useSelector(
    (state) => state.workorder.allWorkorderList
  );
  const handleNavigate = (row) => {
    // setTimeReportList(row?.timeReports);
    dispatch(selectedWorkorder(row));
    // navigate("/dashboard/add-work-order", { state: { workorderData: row } });
    if (setActiveTab) {
      setActiveTab('workOrderInfo')
    } else {
      navigate("/dashboard/add-work-order", { state: { workorderData: row } });
    }
  };

  const workorderColumns = [
    {
      name: <div className="table-content">{t("ao_number")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row?.aonumber}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("ao_name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row?.aoname}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("customer")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.customer?.name}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("email")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.email}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("phone")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.phoneNumber}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("project")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.project?.name}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("status")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("priority")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          onClick={() => {
            handleNavigate(row);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.priority}
        </div>
      ),
    },
  ];

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between">
        <h3 className="pb-2">{t("workorder")}</h3>
        <Button
          className="simple-btn"
          onClick={() => (
            navigate("/dashboard/add-work-order", { state: { customerId } })
          )}
        >
          {t("add_workorder")}
        </Button>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={workorderColumns}
          data={allWorkorderData.filter(
            (item) => item?.customer?._id === customerId
          )}
          responsive={true}
          pagination
          paginationPerPage={40}
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
        />
      </div>
    </div>
  );
};

export default CustomerWorkOrder;
