import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { fixedArticleList } from "../../redux/actions/fixedArticleActions";
import { useTranslation } from "react-i18next";

export default function FixedArticleListing() {
  const [fixedArticles, setFixedArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const columns = [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-fixed-article", {
              state: { fixedArticleData: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("description")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-fixed-article", {
              state: { fixedArticleData: row },
            });
          }}
        >
          {row.description}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("active")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-fixed-article", {
              state: { fixedArticleData: row },
            });
          }}
        >
          {row.active ? "True" : "False"}
        </div>
      ),
    },
  ];

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filteredData = fixedArticles.filter((item) =>
      item?.name.toLowerCase().includes(query?.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const getFixedArticleList = async () => {
    const fixedArtilceData = await fixedArticleList();
    if (fixedArtilceData?.status) {
      setFixedArticles(fixedArtilceData?.data);
    }
  };
  useEffect(() => {
    getFixedArticleList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t("fixed_articles")}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <form action="" className="header-search">
            <input
              type="text"
              placeholder={t("search")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-fixed-article" className="simple-btn">
            {t("add_fixed_articles")}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          data={searchQuery ? filteredData : fixedArticles}
          pagination
          responsive={true}
        />
      </div>
    </div>
  );
}
