import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createWorkorder = async (data) => {
  try {
    const res = await request.post(Constants.create_workorder_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const workorderList = async (clientId) => {
  try {
    const res = await request.get(`${Constants.workorder_list_api_url}/${clientId}`);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const getPriceList = async () => {
  try {
    const res = await request.get(Constants.get_price_lists_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateWorkorder = async (_id, workorder) => {
  try {
    const res = await request.post(Constants.update_workorder_api_url, {
      _id,
      workorder,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const createWorkorderAttributes = async (_id, data, type, invoiceId) => {
  try {
    const res = await request.put(
      Constants.create_workorder_attributes_api_url,
      {
        _id,
        data,
        type,
        invoiceId
      }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
export const updateWorkorderAttributes = async (_id, data, type) => {
  try {
    console.log("=====> data", data);
    const res = await request.put(
      Constants.update_workorder_attributes_api_url,
      {
        _id,
        data,
        type,
      }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
export const deleteWorkorderAttributes = async (_id, data, type) => {
  try {
    const res = await request.put(
      Constants.delete_workorder_attributes_api_url,
      {
        _id,
        data,
        type,
      }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
export const fileUpload = async (data, id, user) => {
  try {
    const res = await request.post(
      `${Constants.upload_file_api_url}/${id}/?uploadedBy=${user}`,
      data
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const getAllworkorderTimereports = async () => {
  try {
    const res = await request.get(Constants.get_allworkorder_timereports);
    console.log("hello response", res)
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
