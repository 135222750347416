import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { machinesList } from "../../redux/actions/machineActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function SelfControlListing() {
  const [machines, setMachines] = useState([])
  const navigate = useNavigate();
  const { t } = useTranslation()

  const columns = [
    {
      name: <div className="table-content">{t('name')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-machine", {
              state: { machineData: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t('reg#')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-machine", {
              state: { machineData: row },
            });
          }}
        >
          {row.regNumber}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t('status')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-machine", {
              state: { machineData: row },
            });
          }}
        >
          {row.isActive ? 'Active' : 'Inactive'}
        </div>
      ),
      wrap: true,
    },
  ];

  const getMachinesList = async () => {
    const machinesData = await machinesList();
    if (machinesData?.status) {
      setMachines(machinesData?.data);
    }
  };

  useEffect(() => {
    getMachinesList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t('machines')}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <form action="" className="header-search">
            <input type="text" placeholder={t("search")} />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-machine" className="simple-btn">
            {t('add_machine')}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable columns={columns} pagination data={machines} responsive={true} paginationPerPage={40} paginationRowsPerPageOptions={[10, 20, 30, 40]} />
      </div>
    </div>
  );
}
