import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { stationsortList } from "../../redux/actions/stationsortActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function StationsortListing() {
  const [stationsorts, setStationsorts] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const columns = [
    {
      name: <div className="table-content">{t('name')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-stationsort", {
              state: { stationsortData: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t('shortening')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-stationsort", {
              state: { stationsortData: row },
            });
          }}
        >
          {row.shortening}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t('status')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-stationsort", {
              state: { stationsortData: row },
            });
          }}
        >
          {row.isActive ? 'Active' : "Inactive"}
        </div>
      ),
      wrap: true,
    },


  ];

  const getStationsortList = async () => {
    const stationsortData = await stationsortList();
    if (stationsortData?.status) {
      setStationsorts(stationsortData?.data);
    }
  };

  useEffect(() => {
    getStationsortList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t('stationsorter')}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <form action="" className="header-search">
            <input type="text" placeholder={t("search")} />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-stationsort" className="simple-btn">
            {t('add_stationsort')}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable columns={columns} pagination data={stationsorts} responsive={true} />
      </div>
    </div>
  );
}
