import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { createStaff, updateStaff } from "../../redux/actions/staffActions";
import { useLocation } from "react-router-dom";
import { AddUpdateStaffValidationSchema } from "./StaffValidationSchema";
import { loginHistoryList } from "../../redux/actions/staffActions";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./AddEditStaffComponent.scss";

import {
  PersonalGroupDropDownValues,
  CompetenceDropDownValues,
  TypeOfEmploymentDropDownValues,
  LoneFormDropDownValues,
  CollectiveAgreementDropDownValues,
  LanguageDropDownValues,
} from "../../constants/Contants";

const AddEditStaffComponent = () => {
  // Personal Information
  const [firstName, setFirstName] = useState("");
  const [username, setUserName] = useState("");
  const [surName, setSurName] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [personalGroup, setPersonalGroup] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [competence, setCompetence] = useState("");
  const [postort, setPostort] = useState("");
  const [typeOfEmployment, setTypeOfEmployment] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState();
  const [loneForm, setLoneForm] = useState("");
  const [employmentNumber, setEmploymentNumber] = useState();
  const [collectiveAgreement, setCollectiveAgreement] = useState("");
  const [email, setEmail] = useState("");
  const [Language, setLanguage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("#ff00ff");
  const [scheduleOvertake, setScheduleOvertake] = useState(false);
  const [textColor, setTextColor] = useState("#ff00ff");
  const [tabKey, setTabKey] = useState("personal");
  const [inputType, setInputType] = useState("password");
  const [isEdit, setIsEdit] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const currentUser = useSelector(state => state.userData.value)

  const { state } = useLocation();
  const { t } = useTranslation();

  const loginHistoryCol = [
    {
      name: <div className="table-content">{t("email")}</div>,
      selector: (row) => (
        <div className="jobPosition" style={{ cursor: "pointer" }}>
          {row.username}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("source")}</div>,
      selector: (row) => (
        <div className="jobPosition" style={{ cursor: "pointer" }}>
          {row.source}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("time")}</div>,
      selector: (row) => (
        <div className="jobPosition" style={{ cursor: "pointer" }}>
          {new Date(row.created_at).toLocaleDateString()}
        </div>
      ),
    },
  ];

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };
  const handleSubmit = async () => {
    const formDataToBeValidated = {
      firstName,
      email,
      username,
      password,
      phoneNumber,
    };
    const { error } = AddUpdateStaffValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      console.log(error);
      error.details.forEach((err) => {
        toast.error(err.message);
        console.log(err);
      });

      return;
    }
    const payload = {
      first_name: firstName,
      username,
      last_name: surName,
      password,
      email,
      phone: phoneNumber,
      ...(!isEdit && { clientId: currentUser?.clientId } || 0),
      isActive: status,
      staffData: {
        address,
        personalGroup,
        zipCode,
        competence,
        postort,
        employmentType: typeOfEmployment,
        socialSecurityNumber,
        loneform: loneForm,
        // employmentNumber,
        collectiveAgreement,
        language: Language,
        backgroundColor,
        scheduleOvertake,
        textColor
      }
    };
    if (isEdit) {
      console.log("UPDATE");
      const data = await updateStaff(state.staffData._id, payload);
      if (data.status) {
        toast.success(t("staff_updated_successfully"));
        // navigate("/dashboard/staff-listing");
      }
    } else {
      const data = await createStaff(payload);
      if (data?.status) {
        toast.success(t("staff_created_successfully"));
      }
    }
  };

  const getLoginHistoryList = async () => {
    const loginHistoryData = await loginHistoryList({ email });
    if (loginHistoryData?.status) {
      setLoginHistory(loginHistoryData?.data);
    }
  };

  useEffect(() => {
    const data = state?.staffData;
    if (data) {
      setIsEdit(true);
      setFirstName(data?.first_name || "");
      setUserName(data?.username || "");
      setSurName(data?.last_name || "");
      setPassword(data?.password)
      setAddress(data?.staffData.address || "");
      setPersonalGroup(data?.staffData.personalGroup || "");
      setZipCode(data?.staffData.zipCode || "");
      setCompetence(data?.staffData.competence || "");
      setPostort(data?.staffData.postort || "");
      setTypeOfEmployment(data?.staffData.employmentType || "");
      setSocialSecurityNumber(data?.staffData.socialSecurityNumber || "");
      setLoneForm(data?.staffData.loneform || "");
      // setEmploymentNumber(data?.employmentNumber || "");
      setCollectiveAgreement(data?.staffData.collectiveAgreement || "");
      setEmail(data?.email || "");
      setLanguage(data?.language || "");
      setPhoneNumber(data?.phone || "");
      setStatus(data?.isActive || true);
      setBackgroundColor(data?.staffData.backgroundColor || "");
      setScheduleOvertake(data?.staffData.scheduleOvertake || false);
      setTextColor(data?.staffData.textColor || "");
    }
  }, []);

  useEffect(() => {
    if (isEdit && email) getLoginHistoryList();
  }, [isEdit]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("staff")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout tabs-layout-container">
            <Tabs
              activeKey={tabKey}
              onSelect={(key) => setTabKey(key)}
              className="mb-3 mt-3 p-0"
            >
              <Tab eventKey="personal" title={t("personal_info")}>
                <Form>
                  <div className="row">
                    {/* First Name */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("first_name")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Username */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("username")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Surname */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("surname")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={surName}
                            onChange={(e) => setSurName(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Password */}
                    {!isEdit &&
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Label>{t("password")}</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              value={password}
                              type={inputType}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    }
                    {/* Email	 */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("email")}</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            value={email}
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Phone Number	 */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("phone_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <PhoneInput
                            country={"us"}
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            enableSearch={true}
                            dropdownStyle={{
                              color: "black",
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Address */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("address")}</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            value={address}
                            type="text"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Personal Group Drop Down */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Tool tip will be here</Tooltip>}
                        >
                          <Form.Label>
                            {t("personal_group")}
                            <span>{/* <FaQuestionCircle /> */}</span>
                          </Form.Label>
                        </OverlayTrigger>

                        <select
                          className="form-select mb-3"
                          onChange={(e) => setPersonalGroup(e.target.value)}
                          value={personalGroup}
                        >
                          <option value={""} selected>
                            Select Personal Group
                          </option>
                          {PersonalGroupDropDownValues.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* Zip Code */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("zip_code")}</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            value={zipCode}
                            type="text"
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Competence Drop Down */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("competence")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setCompetence(e.target.value)}
                          value={competence}
                        >
                          <option value={""} selected>
                            Select Competence
                          </option>
                          {CompetenceDropDownValues.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* Postort */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("postort")}</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            value={postort}
                            type="text"
                            onChange={(e) => setPostort(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Type Of Employment Drop Down */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("type_of_employment")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setTypeOfEmployment(e.target.value)}
                          value={typeOfEmployment}
                        >
                          <option value={""} selected>
                            Select Type Of Employment
                          </option>
                          {TypeOfEmploymentDropDownValues.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* Social security number */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("social_security_number")}</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            value={socialSecurityNumber}
                            type="text"
                            onChange={(e) =>
                              setSocialSecurityNumber(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Loneform Drop Down */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("loneform")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setLoneForm(e.target.value)}
                          value={loneForm}
                        >
                          <option value={""} selected>
                            Select Loneform
                          </option>
                          {LoneFormDropDownValues.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* Employment number	 */}
                    {/* <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("employment_number")}</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            value={employmentNumber}
                            type="text"
                            onChange={(e) =>
                              setEmploymentNumber(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div> */}
                    {/* Collective agreement Drop Down */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("collective_agreement")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) =>
                            setCollectiveAgreement(e.target.value)
                          }
                          value={collectiveAgreement}
                        >
                          <option value={""} selected>
                            Select Collective agreement
                          </option>
                          {CollectiveAgreementDropDownValues.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* Language Drop Down */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("language")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setLanguage(e.target.value)}
                          value={Language}
                        >
                          <option value={""} selected>
                            Select Language
                          </option>
                          {LanguageDropDownValues.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* Account Status */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("account_status")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option value={true} selected>
                            Active
                          </option>
                          <option value={false}>Inactive</option>
                        </select>
                      </Form.Group>
                    </div>
                    {/* Background Color */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("background_color")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={backgroundColor}
                            type="color"
                            onChange={(e) => setBackgroundColor(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Text Color */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("text_color")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={textColor}
                            type="color"
                            onChange={(e) => setTextColor(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/* Schedule overvakare	 */}
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Tool tip will be here</Tooltip>}
                        >
                          <Form.Label>
                            {t("schedule_overtake")}
                            <span>{/* <FaQuestionCircle /> */}</span>
                          </Form.Label>
                        </OverlayTrigger>
                        <Form.Check
                          type="switch"
                          checked={scheduleOvertake}
                          onChange={(e) =>
                            setScheduleOvertake(e.target.checked)
                          }
                          style={{
                            transform: "scale(0.7)",
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Button
                      className="simple-btn mx-auto"
                      onClick={handleSubmit}
                    >
                      {isEdit ? t("update") : t("Submit")}
                    </Button>
                  </div>
                </Form>
              </Tab>
              {isEdit && (
                <Tab eventKey="loginHistory" title={t("login_history")}>
                  <div className="table-responsive alternative-colors">
                    <DataTable
                      columns={loginHistoryCol}
                      data={loginHistory}
                      responsive={true}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditStaffComponent;
