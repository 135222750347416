import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { getFavoriteLists } from "../../redux/actions/favoriteListActions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function FixedArticleListing() {

  const [favoritsLists, setFavoriteLists] = useState([])
  const userData = useSelector((state) => state.userData.value);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const columns = [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-favorite-list", {
              state: { favoriteListData: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("description")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-favorite-list", {
              state: { favoriteListData: row },
            });
          }}
        >
          {row.description}
        </div>
      ),
    },
  ];

  const getFixedArticleList = async () => {
    const data = await getFavoriteLists(userData);
    console.log(data,"data123");
    if (data?.status) {
      if (userData._id !== data.data[0]?.user) {
        const publicFav = data.data?.filter(d => d.isPublic)
        setFavoriteLists(publicFav)
      }
    }
    setFavoriteLists(data?.data?.filter(d => d.user === userData._id));
  }

  useEffect(() => {
    getFixedArticleList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t("favorite_lists")}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <Link to="/dashboard/add-favorite-list" className="simple-btn">
            {t("add_favorite_list")}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          data={favoritsLists}
          pagination
          responsive={true}
        />
      </div>
    </div>
  );
}
