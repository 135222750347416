import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./CustomListing.scss";
import { Link, useNavigate } from "react-router-dom";
import { customerList } from "../../redux/actions/customersActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { customerData } from "../../redux/slices/customerSlice";
import { useDispatch } from "react-redux";

export default function CustomerListing() {
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchColumns, setSearchColums] = useState([]);
  const [selectedSearchCol, setSelactedSearchCol] = useState("name");
  const [statusQuery, setStatusQuery] = useState("");
  const currentUser = useSelector(state => state.userData.value)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const columns = [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-customer")
              dispatch((customerData(row)))
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("contact")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-customer");
            dispatch((customerData(row)))
          }}
        >
          {row.contact}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("email")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-customer")
            dispatch((customerData(row)))
          }}
        >
          {row.email}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("status")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-customer")
             dispatch((customerData(row)))
          }}
        >
          {row.isActive ? "Active" : "In Active"}
        </div>
      ),
    },
    // {
    //   name: <div className="table-content">Actions</div>,
    //   selector: (row) => (
    //     <div
    //       className="jobPosition"
    //       style={{ cursor: "pointer" }}
    //       onClick={() => {
    //         navigate("/dashboard/add-customer", {
    //           state: { customerData: row },
    //         });
    //       }}
    //     >
    //       <span>Edit </span>
    //       <FontAwesomeIcon icon={faEdit} />
    //     </div>
    //   ),
    // },
  ];

  const getCustomerList = async () => {
    const customerData = await customerList(currentUser?.clientId);
    if (customerData?.status) {
      setCustomers(customerData.data);
      setSearchColums([
        { label: "Name", value: "name" },
        { label: "Customer number (0 time)", value: "customerNumber0time" },
        { label: "Customer number (Fortnox)", value: "customerNumberFortnox" },
        { label: "Customer number (Visma)", value: "customerNumberVisma" },
        { label: "Customer number (Other)", value: "customerNumberOther" },
        { label: "Fax Number", value: "faxNumber" },
      ]);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleStatusSearch = (event) => {
    const query = event.target.value;
    setStatusQuery(query);
  };

  useEffect(() => {
    getCustomerList();
    dispatch(customerData(null))
  }, []);

  useEffect(() => {
    if (searchQuery && !statusQuery) {
      const filteredData = customers?.filter((item) =>
        item[selectedSearchCol]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery?.toLowerCase())
      );
      setFilteredData(filteredData);
    }
    if (statusQuery && !searchQuery) {
      const statusData = customers?.filter(
        (item) => item.isActive == JSON.parse(statusQuery)
      );
      setFilteredData(statusData);
    }
    if (searchQuery && statusQuery) {
      const data = customers?.filter(
        (item) =>
          item[selectedSearchCol]
            ?.toString()
            .toLowerCase()
            .includes(searchQuery?.toLowerCase()) &&
          item.isActive == JSON.parse(statusQuery)
      );
      setFilteredData(data);
    }
  }, [searchQuery, statusQuery]);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t("customer")}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <div className="d-flex select-wrapper">
            <select onChange={handleStatusSearch} className="form-control">
              <option value={""}>{t("all")}</option>
              <option value={true}>{t("active")}</option>
              <option value={false}>{t("inactive")}</option>
            </select>
            <select
              className="form-control"
              onChange={(e) => (
                setSelactedSearchCol(e.target.value), setSearchQuery("")
              )}
            >
              {searchColumns?.map((p) => (
                <option value={p.value}>{p.label}</option>
              ))}
            </select>
          </div>
          <form action="" className="header-search">
            <input
              type="text"
              placeholder={t("search")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-customer" className="simple-btn">
            {t("add_customer")}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          pagination
          data={searchQuery || statusQuery ? filteredData : customers}
          responsive={true}
          paginationPerPage={40}
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
        />
      </div>
    </div>
  );
}
