import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import { AddAdminValidationSchema } from "./AddAdminValidationSchema";
import { signup, updateAdmin } from "../../redux/actions/authActions";

const roleOptions = [
  { name: "Admin", value: "admin" },
  { name: "Super Admin", value: "superadmin" },
  { name: "Stuff Admin", value: "stuffadmin" },
  { name: "Staff", value: "staff" },
];

const CreateAdmin = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [userRole, setUserRole] = useState();
  const [isEdit, setIsEdit] = useState(false);

  let navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();

  const handleCreateAdmin = async (e) => {
    const formDataToBeValidated = {
      firstName,
      lastName,
      email,
      password,
    };

    const { error } = AddAdminValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      console.log(error);
      error.details.forEach((err) => {
        toast.error(err.message);
        console.log(err);
      });
      return;
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      role: userRole,
    };

    try {
      if (isEdit) {
        const data = await updateAdmin(state?.adminListData?._id, payload);
        if (data.status) {
          toast.success(t("user_updated_successfully"));
        }
      } else {
        const data = await signup(payload);
        if (data?.status) {
          toast.success(t("user_created_successfully"));
        }
      }
    } catch (error) {
      console.log("ERROR => ", error);
    }
  };

  useEffect(() => {
    const data = state?.adminListData;
    if (data) {
      setIsEdit(true);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      setPassword(data.password);
      setUserRole(data.role);
    } else {
      setIsEdit(false);
    }
  }, [state]);

  return (
    <div>
      <div className="heading">
        <h3>{t("add_admin")}</h3>
      </div>
      <div className="row m-0 bg-white rounded py-5 px-4">
        <div className="col-md-12 mx-auto mb-0">
          <Form className="form-design">
            <div className="row gx-5">
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("first_name")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter your first name..."
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("last_name")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name..."
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("email")}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter your email..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>
              </div>
              {isEdit ? (
                ""
              ) : (
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("password")}</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup className="mb-2">
                        <Form.Control
                          type="password"
                          placeholder="Enter your password..."
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </InputGroup>
                  </Form.Group>
                </div>
              )}
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("role")} *</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setUserRole(e.target.value)}
                    value={userRole}
                  >
                    <option value={""} selected>
                      {t("select")}
                    </option>
                    {roleOptions.map((w) => (
                      <option value={w.value}>{w.name}</option>
                    ))}
                  </select>
                </Form.Group>
              </div>
            </div>
            <div className="mt-4">
              <Button
                className="simple-btn mx-auto"
                onClick={handleCreateAdmin}
              >
                {t("submit")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
