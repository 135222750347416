import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import PdfTypeModal from "../PDFs/PdfsTypeModal";
import CommonButton from "./CommonButton";
const Invoice = ({ exportInvoice }) => {
  const [materialPurchaseValueSum, setMaterialPurchaseValueSum] = useState(0);
  const [materialSellingPriceValueSum, setMaterialSellingPriceValueSum] = useState(0);
  const [workingHoursExpenditure, setWorkingHoursExpenditure] = useState(0)
  const [workingHoursIncome, setWorkingHoursIncome] = useState(0)
  const [purchasePriceSumValue, setPurchasePriceSum] = useState(0);
  const [sellingPriceSumValue, setSellingPriceSum] = useState(0);
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);
  const selectedWorkorderData = useSelector((state) => state.workorder.selectedWorkorder);
  const isFixedPrice = selectedWorkorderData?.priceType === "Fixed price" ? true : false;

  const { t } = useTranslation();

  const formateNumber = (number) => {
    return number?.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).replace(/,/g, ' ')
  }

  useEffect(() => {
    let [purchasePriceSum, sellingPriceSum] = [0, 0];
    selectedWorkorderData?.material.forEach((material) => {
      purchasePriceSum += material.quantity * material.purchasePrice;
      sellingPriceSum += material.quantity * material.sellingPrice;
    });
    setPurchasePriceSum(purchasePriceSum);
    if (selectedWorkorderData?.priceType !== "Fixed price") {

      setSellingPriceSum(sellingPriceSum);
      const sumOfPurchasePrice = selectedWorkorderData?.material.reduce(
        (accumulator, material) => {
          if (material.wholesale === "Underestreprened") {
            return accumulator + material.purchasePrice;
          }
          return accumulator;
        },
        0
      );
      setMaterialPurchaseValueSum(sumOfPurchasePrice);
      const sumOfSellingPrice = selectedWorkorderData?.material.reduce(
        (accumulator, material) => {
          if (material.wholesale === "Underestreprened") {
            return accumulator + material.sellingPrice;
          }
          return accumulator;
        },
        0
      );
      setMaterialSellingPriceValueSum(sumOfSellingPrice);

      const timereports = selectedWorkorderData?.timeReports;
      let sum = 0;
      for (let t of timereports || []) {
        if (t.isApproved && t.hourlyRate) {
         
          sum += t.hourlyRate * Number(t.totalHours.slice(0, 2));
        }
      }
      setWorkingHoursExpenditure(sum)
    } else {
      setSellingPriceSum(0)
      setWorkingHoursIncome(0)
    }
  }, []);

  const calculateTotalProfit = () => {
    return (sellingPriceSumValue - purchasePriceSumValue) + (workingHoursExpenditure + workingHoursIncome)
  }


  return (
    <div className="table-responsive alternative-colors mb-5">
      <Table bordered className="mb-0">
        <thead>
          <tr>
            <th></th>
            <th>{t("expenditure")}</th>
            <th>{t("income")}</th>
            <th>{t("profit")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="heading fw-bold">{t("material_ue")}</td>
            <td>{formateNumber(purchasePriceSumValue)} KR</td>
            <td>{formateNumber(sellingPriceSumValue)} KR</td>
            <td className={!isFixedPrice && `${(sellingPriceSumValue - purchasePriceSumValue) > 0 ? 'bg-success' : 'bg-danger'} text-white`}>
              {isFixedPrice ? '' : `${formateNumber(sellingPriceSumValue - purchasePriceSumValue)} KR`}
            </td>
          </tr>
          <tr>
            <td className="heading fw-bold">{t("working_hours")}</td>
            <td>{formateNumber(workingHoursExpenditure)} KR</td>
            <td>{formateNumber(workingHoursIncome)} KR</td>
            <td>{isFixedPrice ? '' : `${formateNumber(workingHoursExpenditure + workingHoursIncome)} KR`}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td className="total-heading fw-bold">{t("total")}</td>
            <td>{formateNumber(workingHoursExpenditure + purchasePriceSumValue)} KR</td>
            <td>{isFixedPrice ? formateNumber(selectedWorkorderData?.fixedPrice) : formateNumber(workingHoursIncome + sellingPriceSumValue)} KR</td>
            {/* <td>{isFixedPrice ? formateNumber(selectedWorkorderData.fixedPrice - purchasePriceSumValue) : formateNumber(4000 + (materialSellingPriceValueSum - materialPurchaseValueSum))}{""} KR</td> */}
            <td>{isFixedPrice ? formateNumber(selectedWorkorderData?.fixedPrice - purchasePriceSumValue) : formateNumber(calculateTotalProfit())}{""} KR</td>
          </tr>
        </tfoot>
      </Table>
      {/* <div className="w-100 p-3 work-order-bottom-btn flex justify-content-start gap-3 position-fixed bottom-0 bg-light">
        <div className="d-flex gap-3">
          <Button className="simple-btn mx-auto">
            {t("save")}
            <i className="fa fa-save m-2 fs-5"></i>
          </Button>
          <Button
            className="simple-btn mx-auto"
            onClick={() => {
              setIsPdfTypeModalVisible(true);
            }}
          >
            PDF View
          </Button>
          {selectedWorkorderData?.status.toLowerCase() === "new offer" ||
selectedWorkorderData?.status.toLowerCase() === "approved offer" ||
selectedWorkorderData?.status.toLowerCase() === "denied offer" ? null : (
  <Button className="simple-btn mx-auto" onClick={() => exportInvoice()}>
    {t("export_invoice")}
    <i className="fa fa-save m-2 fs-5"></i>
  </Button>
)}
        </div>
      </div> */}

<div>
      <CommonButton
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
        exportInvoice={exportInvoice}
      />
    </div>

      <PdfTypeModal isPdfTypeModalVisible={isPdfTypeModalVisible} setIsPdfTypeModalVisible={setIsPdfTypeModalVisible} />
    </div>
  );
};

export default Invoice;
