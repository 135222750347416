import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createCustomer = async (data, token) => {
  try {
    //Create Customer Request
    const res = await request.post(`${Constants.create_customer_api_url}?fortnoxToken=${token}`, data);
    if (res?.data?.status) {
      return res.data;
    } else {
     return toast.error(res?.data.message);
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateCustomer = async (_id, user) => {
  try {
    //Update Customer Record Request
    const res = await request.post(Constants.update_customer_api_url, {
      _id,
      user,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const customerList = async (clientId) => {
  try {
    //Get All The Customers List Request
    const res = await request.get(`${Constants.customer_list_api_url}/${clientId}`);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const getCustomerById = async (data) => {
  try {
    //Get A Specific Customer By Id Request
    const res = await request.post(`${Constants.get_customer_by_id}/${data}`);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
