import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./constants/LanguageConstants/enTranslation.json";
import translationAR from "./constants/LanguageConstants/svTranslation.json";

const fallbackLng = [localStorage.getItem('otidLang') || 'en'];
const availableLanguages = ["en", "sv"];

const resources = {
    en: {
        translation: translationEN
    },
    sv: {
        translation: translationAR
    },

};
if (initReactI18next === "en-US") {
    initReactI18next = 'en'
}
i18n
    // .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
