import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createPriority = async (data) => {
  try {
    const res = await request.post(Constants.create_priority_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const priorityList = async () => {
  try {
    const res = await request.get(Constants.priority_list_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updatePriority = async (_id, priority) => {
  try {
    const res = await request.post(Constants.update_priority_api_url, {
      _id,
      priority,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
