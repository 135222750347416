import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createSelfControlLists = async (payload) => {
  try {
    const res = await request.post(
      Constants.create_self_control_lists_api_url,
      {
        name: payload.name,
        points: payload.points,
      }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const selfControlLists = async () => {
  try {
    const res = await request.get(Constants.self_control_lists_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateSelfControlLists = async (_id, selfControlpayload) => {
  try {
    const res = await request.post(
      Constants.update_self_control_lists_api_url,
      {
        _id,
        selfControl: selfControlpayload,
      }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const deleteSelfControlPoint = async (data) => {
  try {
    const res = await request.post(
      Constants.delete_selcontrol_point_api_url, { data }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateSelfControlListPoint = async (data) => {
  try {
    const res = await request.patch(
      Constants.update_selcontrollist_point_api_url, { data }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
