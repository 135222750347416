import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { TimeReportColumns, calculateTimeDifference } from "../columns";
import { HOURS, OVERTIME_HOURS, MINUTES } from "../../../constants/Contants";
// import { staffList } from "../../../redux/actions/staffActions";
import { timeReportValidationSchema } from "../AddUpdateWorkorderValidationSchema";
import TimeReportPDFView from "./timeReportPDF/TimeReportPDFView";
import calendarImg from "../../../assets/images/calendar.svg";
import clockImg from "../../../assets/images/clock.svg";
import PlusImg from "../../../assets/images/plus.svg";
import CrossSvg from "../../../assets/images/cross.svg";
import { updateWorkorderAttributes, deleteWorkorderAttributes, createWorkorderAttributes } from "../../../redux/actions/workorderActions";
import { selectedWorkorder } from "../../../redux/slices/workorderSlice";
import { workroleList } from "../../../redux/actions/workrolesActions";
import PdfTypeModal from "../PDFs/PdfsTypeModal";
import { invoiceData } from "../../../redux/slices/timereportSlice";
import { createInvoice } from "../../../redux/actions/invoiceActions";
import CommonButton from "./CommonButton";
const TimeReportTab = ({ createTimeReport, activeTab, exportInvoice }) => {
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [fromHours, setFromHours] = useState("07");
  const [fromMinutes, setFromMinutes] = useState("00");
  const [tillHours, setTillHours] = useState("17");
  const [tillMinutes, setTillMinutes] = useState("00");
  const [addCompensation, setAddCompensation] = useState(false);
  const [timeReportNotes, setTimeReportNotes] = useState("");
  const [drivingAllowanceUOA, setDrivingAllowanceUOA] = useState("0");
  const [drivingAllowanceIOA, setDrivingAllowanceIOA] = useState("0");
  const [restidUOA, setrestidUOA] = useState("0");
  const [restidIOA, setrestidIOA] = useState("0");
  const [reportCases, setReportCases] = useState([]);
  const [totalHours, setTotalHours] = useState("08");
  // const [allStaff, setAllStaff] = useState([]);
  const [showClockTime, setShowClockTime] = useState(false);
  const [hoursOnWeekend, setHoursOnWeekend] = useState("");
  const [overtime, setOverTime] = useState("");
  const [routineHours, setRoutineHours] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTimeReportId, setSelectedTimeReportId] = useState("");
  const [selectedTimeReportRow, setSelectedTimeReportRow] = useState([]);
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);
  const [workroles, setWorkroles] = useState([]);
  const [hourlyRate, setHourlyRate] = useState('');
  const user = useSelector((state) => state.userData?.value);
  const selectedWorkorderData = useSelector((state) => state.workorder.selectedWorkorder);
  const [timeReportsList, setTimeReportList] = useState(selectedWorkorderData?.timeReports);
  const [selectedStaff, setSelectedStaff] = useState(
    `${user?.first_name} ${user.last_name}`
  );

  // translation
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const minDate = new Date(currentYear - 5, currentDate.getMonth(), currentDate.getDate());
  const maxDate = new Date(currentYear + 5, currentDate.getMonth(), currentDate.getDate());

  const handleCreateTimeReport = async (
    reportPayload,
    remove,
    timeReportEdit,
    timeReportId
  ) => {
    if (timeReportEdit) {
      const confirm = window.confirm("Are you sure you want to edit this timereport")
      let data;
      if (confirm) {
        reportPayload._id = timeReportId;
        data = await updateWorkorderAttributes(
          selectedWorkorderData?._id,
          reportPayload,
          "timeReports"
        );
      }
      if (data.status) {
        setTimeReportList(data?.data.timeReports);
        dispatch(selectedWorkorder(data?.data));
        toast.success(t("time_report_updated_successfully"));
        return { success: true };
      }
    } else {
      let data;
      if (remove) {
        data = await deleteWorkorderAttributes(
          selectedWorkorderData?._id,
          reportPayload[0],
          "timeReports"
        );
      } else {
        data = await createWorkorderAttributes(
          selectedWorkorderData?._id,
          reportPayload,
          "timeReports"
        );
      }
      if (data?.status) {
        setTimeReportList(data?.data.timeReports);
        dispatch(selectedWorkorder(data?.data));
        if (remove) {
          return toast.success(t("time_report_deleted_successfully"));
        }
        toast.success(t("time_report_added_successfully"));
        return { success: true };
      }
    }
  };

  function formatOvertime(input) {
    if (input === "00" || input === "" || input === "00:00") {
      return "";
    }
    const parts = input.split(" ");
    const formattedParts = parts.map((part) => {
      const timeParts = part.split(":");
      if (timeParts.length === 2) {
        const hours = timeParts[0];
        const minutes = timeParts[1];
        if (!isNaN(hours) && !isNaN(minutes)) {
          return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
        }
      }
      return `${part}:00`;
    });

    return formattedParts.join(" ");
  }
  function formatMinutesToHourMinute(minutes) {
    if (isNaN(minutes) || minutes < 0) {
      return "Invalid input";
    }
    if (minutes === 0) {
      return "00:00";
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${remainingMinutes < 10 ? "0" : ""
      }${remainingMinutes}`;
    return formattedTime;
  }
  function convertTimeFormat(inputTime) {
    const lastIndex = inputTime?.lastIndexOf(":");
    if (lastIndex === -1) {
      return inputTime;
    }
    const lastTwoChars = inputTime.slice(lastIndex - 2, lastIndex);
    if (lastTwoChars === "00") {
      return inputTime.slice(0, lastIndex - 2) + inputTime.slice(lastIndex + 1);
    } else {
      return inputTime;
    }
  }
  function changeDayFormat(timeString) {
    const timeParts = timeString.split(":");
    let hours = parseInt(timeParts[0], 10);
    let minutes = parseInt(timeParts[1] || "0", 10);
    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59
    ) {
      return "Invalid time format";
    }
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  }

  const handleSelectedTimereportRow = async (row) => {
    // const existingReport = selectedTimeReportRow.length && selectedTimeReportRow?.find((s) => (s._id === row._id))
    const existingReport = row.isApproved;
    if (!existingReport) {
      //   const filteredData = selectedTimeReportRow.filter((s) => (s._id !== row._id))
      //   setSelectedTimeReportRow(filteredData)
      // } else {
      const conformation = window.confirm(t('approve_timesheet') + "\n" + t('deleted_approve_timesheet'))
      if (conformation) {
        const payload = {
          ...row,
          isApproved: true
        }
        const res = await handleCreateTimeReport(payload, null, true, row._id);
        console.log("=====created wert", res)
        // setSelectedTimeReportRow(selectedWorkorder?.timeReports?.filter(e => e.isApproved))
        // setSelectedTimeReportRow([...selectedTimeReportRow, row])
      }
    }
  }
  console.log("selectedTimeReportRow", selectedTimeReportRow[0]?._id === timeReportsList[0]?._id)
  const selectedRow = (row) => {
    // const data = selectedTimeReportRow.length && selectedTimeReportRow?.find((e) => (e._id === row._id))
    const data = row?.isApproved;
    if (data) return true;
  }

  const handleEditTimereport = (row, event) => {
    if (!isEdit) {
      const confirm = window.confirm("Are you sure you want to edit this timereport")
      if (confirm) {
        selectedTimeReport(row, event)
      }
    } else {
      selectedTimeReport(row, event)
    }
  }
  const updatedColumns = [
    {
      name: <div className="table-content">{t("GA")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          <input type="checkbox" className="cursor-pointer" checked={row.isApproved} onChange={() => handleSelectedTimereportRow(row)} />
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("AO")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {selectedWorkorderData?.aonumber}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("date")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {moment(row.date).format('YYYY-MM-DD')}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("start")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {/* {row.clockTime
            ? convertTimeFormat(
              new Date(row?.start).toLocaleString().split(",")[1]
            )
            : null} */}
          {row.clockTime
            ?
            moment(row?.start).format('HH:mm')
            : null}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("stop")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {/* {row.clockTime
            ? convertTimeFormat(
              new Date(row.stop).toLocaleString().split(",")[1]
            )
            : null} */}
          {row.clockTime
            ?
            moment(row?.stop).format('HH:mm')
            : null}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("staff")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {selectedStaff}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("notes")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {row.note}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("day")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {row.routineHours
            ? changeDayFormat(row.routineHours)
            : Number(row.totalHours) > 10
              ? "10"
              : row.totalHours}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OB1</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {row.surcharge}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Breaks</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {row.cases?.length ? calculateTimeDifference(row.cases[0]) : ""}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT1</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {formatOvertime(row.overtime)}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT2</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {formatOvertime(row.hoursOnWeekend)}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Driving</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {formatMinutesToHourMinute(
            +row.allowance.restidUOA + +row.allowance.restidIOA
          )}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">KM</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {`${+row.allowance.drivingAllowanceUOA +
            +row.allowance.drivingAllowanceIOA ==
            0
            ? 0
            : `${+row.allowance.drivingAllowanceUOA +
            +row.allowance.drivingAllowanceIOA
            }.00`
            }`}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("sum")}</div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          {row.clockTime ? `${row.totalHours}:00` : row.totalHours}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content"></div>,
      selector: (row) => (
        <div
          className={
            selectedRow(row)
              ? "jobPosition editedRow"
              : row._id === selectedTimeReportRow[0]?._id ? "jobPosition edited-row" : "jobPosition"
          }
        >
          <div className="work-order-bottom-btn bg-transparent">
            {row.isExported ? '$' :
              <div className="d-flex">
                <div
                  className="bd-transparent text-secondary"
                  onClick={() => handleRemoveReport(row)}
                >
                  <i className="fa fa-trash m-2 fs-5 cursor-pointer"></i>
                </div>
                <div
                  id={`${selectedTimeReportId == row?._id && isEdit ? "close" : "edit"
                    }`}
                  className="bd-transparent text-secondary"
                  // onClick={(event) => selectedTimeReport(row, event)}
                  onClick={(event) => handleEditTimereport(row, event)}
                >
                  <i
                    className={`fa fa-${selectedTimeReportId == row?._id && isEdit
                      ? "close"
                      : "pencil"
                      } m-2 fs-5 cursor-pointer`}
                  ></i>
                </div>
              </div>
            }
          </div>
        </div>
      ),
      wrap: true,
    },
  ];

  const selectedTimeReport = (row, event) => {
    if (event.currentTarget.id == "close") {
      setDate(moment(new Date()).format("YYYY-MM-DD"));
      setTimeReportNotes("");
      setSelectedTimeReportId("");
      setSelectedTimeReportRow([]);
      setAddCompensation(false);
      setIsEdit(false);
      setOverTime("");
      setHoursOnWeekend("");
      return;
    }
    const { date: rowDate, note: rowNotes, _id, allowance, cases } = row;
    const { drivingAllowanceUOA: UOA, drivingAllowanceIOA: IOA, restidUOA: resUOA, restidIOA: resIOA } = allowance;
    setIsEdit(true);
    setDate(moment(rowDate).format("YYYY-MM-DD"));
    setTimeReportNotes(rowNotes);
    setSelectedTimeReportId(_id);
    setTotalHours(Number(row.totalHours) > 10 ? "10" : row.totalHours);
    setRoutineHours(row.routineHours)
    setOverTime(row.overtime == "00" ? "" : row.overtime);
    setShowClockTime(row.clockTime);
    // setShowClockTime(row.clockTime)
    setHoursOnWeekend(row.hoursOnWeekend == "00" ? "" : row.hoursOnWeekend);
    if (UOA != 0 || IOA != 0 || restidUOA != 0 || restidIOA != 0) {
      setDrivingAllowanceUOA(UOA);
      setDrivingAllowanceIOA(IOA);
      setrestidUOA(resUOA);
      setrestidIOA(resIOA);
      setAddCompensation(true);
    }
    if (cases?.length) {
      setReportCases(cases);
    }
    selectedWorkorderData?.timeReports.map((timereport) => {
      if (timereport._id === row._id) {
        setSelectedTimeReportRow([row]);
      }
    });
  };

  const handleOvertime2 = () => {
    const dayOfWeek = new Date(date).getDay();
    if ((dayOfWeek === 0 || dayOfWeek === 6) && showClockTime == false) {
      const time = sumTimeFields([`${totalHours}:00`, `${hoursOnWeekend}:00`]);
      return time;
    } else {
      return hoursOnWeekend;
    }
  };

  const getNextDate = (endDate) => {
    const dayCrossed = handleCrossedNextDate();
    if (dayCrossed) {
      const today = new Date(endDate);
      today.setDate(today.getDate() + 1);
      return today;
    } else {
      return endDate;
    }
  };
  const handleArticleSubmit = async () => {
    const formDataToBeValidated = {
      ...(addCompensation && { drivingAllowanceUOA }),
      ...(addCompensation && { drivingAllowanceIOA }),
      ...(addCompensation && { restidIOA }),
      ...(addCompensation && { restidUOA }),
    };
    const { error } = timeReportValidationSchema(t).validate(
      formDataToBeValidated
    );
    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });
      return;
    }
    if (user?.role !== "admin") {
      return toast.warning("Access denied");
    }
    const currentDay = new Date(date).toDateString().split(" ")[0];
    var startDateTime = moment(date).set({
      hour: fromHours,
      minute: fromMinutes
    })._d;
    var endDateTime = moment(date).set({
      hours: tillHours,
      minutes: tillMinutes
    })._d;
    const payload = {
      date,
      start: showClockTime ? startDateTime : date,
      stop: showClockTime ? getNextDate(endDateTime) : date,
      machine: null,
      totalHours: showClockTime ? totalHours : sumTimeFields([`${totalHours}:00`, `${overtime}:00`, `${hoursOnWeekend}:00`]),
      overtime: currentDay == "Sat" || currentDay == "Sun" ? "" : overtime,
      routineHours,
      hoursOnWeekend: handleOvertime2(),
      user: user?._id,
      cases: reportCases,
      clockTime: showClockTime,
      note: timeReportNotes,
      hourlyRate: Number(hourlyRate),
      allowance: {
        drivingAllowanceUOA,
        drivingAllowanceIOA,
        restidIOA,
        restidUOA,
      },
    };
    const reportStartTime = `${fromHours}:${fromMinutes}`;
    const reportEndTime = `${tillHours}:${tillMinutes}`;

    const isNotBetween = isTimeNotBetweenReportTime(
      reportCases,
      reportStartTime,
      reportEndTime
    );
    if (isNotBetween) {
      return toast.error(t("conflict_between_time_range"));
    }
    const res = await handleCreateTimeReport(
      payload,
      null,
      isEdit,
      selectedTimeReportId
    );
    if (res?.status) {
      setDrivingAllowanceUOA("0");
      setDrivingAllowanceIOA("0");
      setrestidUOA("0");
      setrestidIOA("0");
      setTimeReportNotes("");
      setSelectedTimeReportId("");
      setIsEdit(false);
      setAddCompensation(false);
      setReportCases([]);
      setSelectedTimeReportRow("");
    }
  };

  const handleRemoveReport = (row) => {
    const userConfirmed = window.confirm(
      t("are_you_sure_you_want_to_delete_this_time_report?")
    );
    if (userConfirmed) {
      const filteredReports = timeReportsList?.filter((r) => r._id == row._id);
      handleCreateTimeReport(filteredReports, true);
    }
  };

  const handleAddCase = () => {
    setReportCases([
      ...reportCases,
      { startHour: "06", startMinute: "00", endHour: "06", endMinute: "00" },
    ]);
  };

  const handleRemoveReportCase = (index) => {
    const filtered = reportCases.filter((f, i) => i !== index);
    setReportCases(filtered);
  };

  const handleCasesHourMinute = (value, index, field) => {
    const selected = reportCases[index];
    selected[field] = value;
    reportCases[index] = selected;
    setReportCases([...reportCases]);
  };

  const calculateTimeDuration = (lastDate) => {
    var startDate = new Date(date);
    startDate.setHours(+fromHours, 0, 0);
    var endDate = new Date(lastDate);
    endDate.setHours(+tillHours, 0, 0);
    var durationInMilliseconds = endDate - startDate;
    var hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
    var minutes = Math.floor(
      (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (hours.toString().length < 2) {
      setTotalHours(`0${hours}`);
    } else {
      setTotalHours(hours);
    }
  };

  const updateTimes = (value) => {
    var startHour = 7;
    setTotalHours(value);
    var selectedHours = value;

    var endHour = startHour + +selectedHours;
    var startTime = moment({ hour: startHour }).format("HH:mm");
    var endTime = moment({ hour: endHour }).format("HH:mm");
    const start = startTime.split(":");
    const end = endTime.split(":");
    setFromHours(start[0]);
    setFromMinutes(start[1]);
    setTillHours(end[0]);
    setTillMinutes(end[1]);
  };

  const calculateOverTime = () => {
    const totalMinutes = +tillHours * 60 + +tillMinutes;
    let timeDifferenceInMinutes = totalMinutes - 17 * 60;
    if (timeDifferenceInMinutes < 0) {
      timeDifferenceInMinutes = 0;
    } else if (timeDifferenceInMinutes > 2 * 60) {
      timeDifferenceInMinutes = 2 * 60; // Limit to 2 hours (19:00 - 17:00)
    }
    const hours = Math.floor(timeDifferenceInMinutes / 60);
    const minutes = timeDifferenceInMinutes % 60;
    setOverTime(`0${hours}:${minutes === 0 ? `0${minutes}` : minutes}`);
  };

  const calculateTimeAfter19 = () => {
    const totalMinutes = +tillHours * 60 + +tillMinutes;
    const timeDifference = totalMinutes - 19 * 60;

    if (timeDifference < 0) {
      return "It is not yet 19:00 today.";
    }
    const resultHours = Math.floor(timeDifference / 60);
    const resultMinutes = timeDifference % 60;
    setHoursOnWeekend(
      `${resultHours}:${resultMinutes < 10 ? "0" : ""}${resultMinutes}`
    );
  };

  const sumTimeFields = (timeFields) => {
    let totalMinutes = 0;
    for (const timeField of timeFields) {
      const [hours, minutes] = timeField?.split(":").map(Number);
      totalMinutes += hours * 60 + minutes;
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedTotalTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return formattedTotalTime;
  };

  const isTimeNotBetweenReportTime = (
    timeArray,
    separateStartTime,
    separateEndTime
  ) => {
    const separateStart = new Date(`${date}T${separateStartTime}:00`);
    const separateEnd = new Date(`${date}T${separateEndTime}:00`);
    for (const time of timeArray) {
      const start = new Date(
        `${date}T${time.startHour}:${time.startMinute}:00`
      );
      const end = new Date(`${date}T${time.endHour}:${time.endMinute}:00`);
      if (start < separateStart || end > separateEnd) {
        return true;
      }
    }
    return false;
  };

  const handleCrossedNextDate = () => {
    const startDate = new Date(`${date}T${fromHours}:00`);
    const endDate = new Date(`${date}T${tillHours}:00`);
    return endDate < startDate;
  };

  const calculateTime7To17 = () => {
    let durationMinutes = 0;
    const from = +fromHours;
    const fromM = +fromMinutes;
    const tillH = 17;
    const tillM = +`00`;

    if (from < tillH || (from === tillH && fromM <= tillM)) {
      durationMinutes = tillH * 60 + tillM - (from * 60 + fromM);
    } else {
      durationMinutes = (24 - from) * 60 - fromM + (tillH * 60 + tillM);
    }
    const durationHours = Math.floor(durationMinutes / 60);
    durationMinutes %= 60;
    const time = `${durationHours}:${durationMinutes}`;
    setRoutineHours(time);
    console.log(`Duration: ${durationHours} hours ${durationMinutes} minutes`);
  };

  const getWorkroles = async() => {
    const data = await workroleList()
    if(data.status) {
      setWorkroles(data?.data)
      setHourlyRate(data?.data[0].hourlyRate)
    }
  }


  useEffect(() => {
    const dateCrossed = handleCrossedNextDate();
    if (dateCrossed) {
      const d = getNextDate(date);
      calculateTimeDuration(d);
      setOverTime("02:00");
      const totalTime = sumTimeFields([`05:00`, `${tillHours}:${tillMinutes}`]);
      setHoursOnWeekend(totalTime);
    } else {
      calculateTimeDuration(date);
    }
    if (showClockTime && !dateCrossed) {
      calculateOverTime();
    }
    //+fromHours >= 19 && 
    if (+fromHours <= 23 && !dateCrossed) {
      calculateTimeAfter19();
    }
    calculateTime7To17();
  }, [fromHours, fromMinutes, tillHours, tillMinutes]);

  useEffect(() => {
    setSelectedTimeReportRow(timeReportsList?.filter(e => e.isApproved))
    getWorkroles()
  }, [])


  return (
    <div>
      <div className="col-lg-12 d-flex align-items-center justify-content-between my-3">
      </div>
      <div className={`row ${isEdit ? 'tab-heading' : 'bg-light'} mx-1 my-2 p-3`}>
        <div className="col-md-6 p-3 pe-5">
          <div className="d-flex justify-content-between">
            <h5>{isEdit ? t("edit_time_report") : t("add_time_report")}</h5>
            <div className="d-flex">
              <p>{t("show_clock_time")}</p>
              <input
                type="checkbox"
                className="m-1 mb-3 cursor-pointer"
                checked={showClockTime}
                onChange={() => (
                  setShowClockTime(!showClockTime),
                  setTillHours("17"),
                  setTillMinutes("00"),
                  setHoursOnWeekend("")
                )}
              />
            </div>
          </div>
          <Form className="form-design datetime-design p-0 mb-3">
            <div className="w-100 d-flex flex-wrap gap-2 align-items-center justify-content-around">
              {/* Item Number */}
              <div className="p-2">
                <Form.Group>
                  <Form.Label>
                    <img
                      src={calendarImg}
                      className="me-2 mb-1"
                      alt="dateicon"
                    />
                    {t("date")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      min={minDate.toISOString().split('T')[0]}
                      max={maxDate.toISOString().split('T')[0]}
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              {!showClockTime && (
                <>
                  <div className="p-2">
                    <Form.Group>
                      <Form.Label className="py-1">{t("hours")}</Form.Label>
                      <InputGroup className="min-width">
                        <select
                          className="form-select cursor-pointer"
                          // onChange={(e) => updateTimes(e.target.value)}
                          onChange={(e) => (setTotalHours(e.target.value), setRoutineHours(e.target.value))}
                          value={routineHours.substring(0, 2)}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="p-2">
                    <Form.Group>
                      <Form.Label className="py-1">
                        {t("overtime_hours")}
                      </Form.Label>
                      <InputGroup className="min-width">
                        <select
                          className="form-select cursor-pointer"
                          onChange={(e) => setOverTime(e.target.value)}
                          value={overtime}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="p-2">
                    <Form.Group>
                      <Form.Label className="py-1">
                        {t("other_hours")}
                      </Form.Label>
                      <InputGroup className="min-width">
                        <select
                          className="form-select cursor-pointer"
                          // onChange={(e) => updateTimes(e.target.value)}
                          onChange={(e) => setHoursOnWeekend(e.target.value)}
                          value={hoursOnWeekend}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </>
              )}

              {showClockTime && (
                <>
                  <div className=" p-2">
                    <Form.Group>
                      <Form.Label>
                        <img
                          src={clockImg}
                          className="me-2 mb-1"
                          alt="timeIcon"
                        />
                        {t("Start")}
                      </Form.Label>
                      <InputGroup className="d-flex gap-1 flex-nowrap">
                        <select
                          className="select-time-report bg-light px-2 cursor-pointer"
                          onChange={(e) => setFromHours(e.target.value)}
                          value={fromHours}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                        :
                        <select
                          className="select-time-report bg-light px-2 cursor-pointer"
                          onChange={(e) => setFromMinutes(e.target.value)}
                          value={fromMinutes}
                        >
                          {MINUTES?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="p-2">
                    <Form.Group>
                      <Form.Label>
                        <img
                          src={clockImg}
                          className="me-2 mb-1"
                          alt="timeIcon"
                        />
                        {t("stop")}
                      </Form.Label>
                      <InputGroup className="d-flex gap-1">
                        <select
                          className="select-time-report bg-light px-2 cursor-pointer"
                          onChange={(e) => setTillHours(e.target.value)}
                          value={tillHours}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                        :
                        <select
                          className="select-time-report  bg-light px-2 cursor-pointer"
                          onChange={(e) => setTillMinutes(e.target.value)}
                          value={tillMinutes}
                        >
                          {MINUTES?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </>
              )}
              <div className="p-2">
                    <Form.Group>
                      <Form.Label className="py-1">
                        {t("workroles")}
                      </Form.Label>
                      <InputGroup className="min-width">
                        <select
                          className="form-select cursor-pointer"
                          onChange={(e) => setHourlyRate(e.target.value)}
                          value={hourlyRate}
                        >
                          {workroles?.map((c) => (
                            <option value={c.name}>{c.name}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
              <div className="d-flex justify-content-end w-25 mt-4">
                <Form.Group>
                  <div>
                    <InputGroup>
                      <Form.Control type="text" value={selectedStaff} />
                    </InputGroup>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Form>

          {/* time report notes */}
          <div className="col-lg-12">
            <Form.Group className="my-4">
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={timeReportNotes}
                  onChange={(e) => setTimeReportNotes(e.target.value)}
                  className="timereport-textarea"
                />
              </InputGroup>
            </Form.Group>
          </div>

          <div className="mt-3">
            <Button
              className="simple-btn simple-btn-success w-100"
              onClick={handleArticleSubmit}
            >
              {isEdit ? t("update_time_report") : t("create_time_report")}
            </Button>
          </div>
        </div>

        <div className="col-md-6 border-start p-3">
          <div className="d-flex mb-3 gap-3 justify-content-end ms-auto">
            <Button
              className="simple-btn bg-pri gap-3 rounded-0 min-width-auto px-3 text-white "
              onClick={handleAddCase}
            >
              <img src={PlusImg} className="me-0 mb-1" alt="plusicon" />
              {t("add_break")}
            </Button>
            <Button
              className="simple-btn bg-secondri gap-3 rounded-0 min-width-auto px-3 text-white"
              onClick={() => setAddCompensation(!addCompensation)}
            >
              <img src={PlusImg} className="me-0 mb-1" alt="plusicon" />
              {t("add_allowance")}
            </Button>
          </div>
          {reportCases.length > 0 &&
            reportCases.map((r, i) => (
              <Form className="form-design p-3 ps-4">
                <div className="row g-3 gy-4 align-items-end">
                  <div className="col-lg-5">
                    <Form.Group>
                      <Form.Label>
                        <img
                          src={clockImg}
                          className="me-2 mb-1"
                          alt="timeIcon"
                        />
                        {t("from")}
                      </Form.Label>
                      <InputGroup className="d-flex gap-1">
                        <select
                          className="form-select cursor-pointer"
                          onChange={(e) =>
                            handleCasesHourMinute(
                              e.target.value,
                              i,
                              "startHour"
                            )
                          }
                          value={r.startHour}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                        :
                        <select
                          className="form-select cursor-pointer"
                          onChange={(e) =>
                            handleCasesHourMinute(
                              e.target.value,
                              i,
                              "startMinute"
                            )
                          }
                          value={r.startMinute}
                        >
                          {MINUTES?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-lg-5">
                    <Form.Group>
                      <Form.Label>
                        <img
                          src={clockImg}
                          className="me-2 mb-1"
                          alt="timeIcon"
                        />
                        {t("from")}
                      </Form.Label>
                      <InputGroup className="d-flex gap-1">
                        <select
                          className="form-select cursor-pointer"
                          onChange={(e) =>
                            handleCasesHourMinute(e.target.value, i, "endHour")
                          }
                          value={r.endHour}
                        >
                          {HOURS?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                        :
                        <select
                          className="form-select cursor-pointer"
                          onChange={(e) =>
                            handleCasesHourMinute(
                              e.target.value,
                              i,
                              "endMinute"
                            )
                          }
                          value={r.endMinute}
                        >
                          {MINUTES?.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 mb-2">
                    <Button
                      className="cross-btn btn border-0  gap-3 rounded-0 min-width-auto p-0"
                      onClick={() => handleRemoveReportCase(i)}
                    >
                      <img src={CrossSvg} alt="crossIcon" />
                    </Button>
                  </div>
                </div>
              </Form>
            ))}
          <hr className="mx-4 mt-5 " />
          {addCompensation && (
            <Form className="form-design p-3 ps-4">
              <div className="row g-3 gy-4 align-items-end">
                {/* Item Number */}
                <div className="col-lg-6">
                  <Form.Group className="km-field">
                    <Form.Label>{t("driving_allowance")} (UOA)</Form.Label>
                    <div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          value={drivingAllowanceUOA}
                          onChange={(e) =>
                            setDrivingAllowanceUOA(e.target.value)
                          }
                        />
                      </InputGroup>
                      <span>km</span>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="km-field">
                    <Form.Label>{t("restid")} (UOA)</Form.Label>
                    <div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          value={restidUOA}
                          onChange={(e) => setrestidUOA(e.target.value)}
                        />
                      </InputGroup>
                      <span>min</span>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="km-field">
                    <Form.Label>{t("driving_allowance")} (IOA)</Form.Label>
                    <div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          value={drivingAllowanceIOA}
                          onChange={(e) =>
                            setDrivingAllowanceIOA(e.target.value)
                          }
                        />
                      </InputGroup>
                      <span>km</span>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="km-field">
                    <Form.Label>{t("restid")} (I.O.A.)</Form.Label>
                    <div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          value={restidIOA}
                          onChange={(e) => setrestidIOA(e.target.value)}
                        />
                      </InputGroup>
                      <span>min</span>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>

      <>
        <div className="w-100 p-2 mt-3 mb-4 text-center tab-heading">
          <h2 className="fs-6 fw-bold mb-2">{t("submitted_time_reports")}</h2>
          <hr />
        </div>
        <div className="table-responsive alternative-colors mb-5 rounded-0">
          <DataTable
            columns={updatedColumns}
            data={timeReportsList?.filter((t) => !t.isDeviation)}
            responsive={true}
            className="table-custum1"
          />
        </div>
        {/* <div className="w-100 p-3 work-order-bottom-btn flex justify-content-start gap-3 position-fixed bottom-0 bg-light">
          <div>
            <Button className="simple-btn mx-auto">
              {t("save")}
              <i className="fa fa-save m-2 fs-5"></i>
            </Button>
          </div>
       
          <div>
            <Button
              className="simple-btn mx-auto"
              onClick={() => {
                setIsPdfTypeModalVisible(true);
              }}
            >
              PDF View
            </Button>
          </div>
          <div>
            <Button className="simple-btn mx-auto" onClick={() => exportInvoice()}>
              {t("export_invoice")}
              <i className="fa fa-save m-2 fs-5"></i>
            </Button>
          </div>
        </div> */}
    <div>
      <CommonButton
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
        exportInvoice={exportInvoice}
      />
    </div>

      </>
      <PdfTypeModal isPdfTypeModalVisible={isPdfTypeModalVisible} setIsPdfTypeModalVisible={setIsPdfTypeModalVisible} />
    </div >
  );
};

export default TimeReportTab;
