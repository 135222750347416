import axios from 'axios';
import { Constants } from '../constants/Contants';
import { LocalStorageKeys } from '../constants/LocalStorageKeys';
import { toast } from "react-toastify"
const request = () => {
    //Customize The Axios Request Package.
    let instance = axios.create({});
    instance.interceptors.request.use(async (config) => {
        var jwtToken = await localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
        config.headers.Authorization = jwtToken ? `${jwtToken}` : '';
        config.headers.Origin = window.location.origin;
        config.baseURL = Constants.baseURL;
        return config;
    });
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            // console.log('error.response.data.error', error.response.data.error);
            toast.error(error.response?.data.error);
            if ([401, 403].includes(error?.response?.status)) {
                window.location.href = '/';
            }
            return error.response;
        }
    );
    return instance;
};
export default request();
