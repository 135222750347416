
export const getDeductionPercentage = (option) => {
  switch (option) {
    case 'No Deduction':
      return { percentage: 0, limit: null }
    case 'Solcellsinstallation':
      return { percentage: 20, limit: null }
    case 'Lagring av egenproducerad elenergi':
      return { percentage: 50, limit: null }
    case 'Laddningspunkt elfordon':
      return { percentage: 50, limit: null }
    case 'Murning':
      return { percentage: 30, limit: 50000 }
    case 'Glas/plåtarbete':
      return { percentage: 30, limit: 50000 }
    case 'Mark/dräneringsarbete':
      return { percentage: 30, limit: 50000 }
    case 'Bygg':
      return { percentage: 30, limit: 50000 }
    case 'Målnings/tapetsering':
      return { percentage: 30, limit: 50000 }
    case 'VVS':
      return { percentage: 30, limit: 50000 }
    case 'EL':
      return { percentage: 30, limit: 50000 }
    case 'Städning':
      return { percentage: 50, limit: 75000 }
    case 'Hemtextil och klädvård':
      return { percentage: 50, limit: 75000 }
    case 'Matlagning':
      return { percentage: 50, limit: 75000 }
    case 'Snöskottning':
      return { percentage: 50, limit: 75000 }
    case 'Trädgårdsarbete':
      return { percentage: 50, limit: 75000 }
    case 'Barnpassning':
      return { percentage: 50, limit: 75000 }
    case 'Annan omsorg':
      return { percentage: 50, limit: 75000 }
    case 'Läxhjälp':
      return { percentage: 50, limit: 75000 }
    case 'IT-Tjänster':
      return { percentage: 50, limit: 75000 }
    case 'Tillsyn bostad':
      return { percentage: 50, limit: 75000 }
    case 'Möblering':
      return { percentage: 50, limit: 75000 }
    case 'Transporttjänster':
      return { percentage: 50, limit: 75000 }
    case 'Tvätt och vård av kläder':
      return { percentage: 50, limit: 75000 }
    default:
      return { percentage: 0, limit: null }
  }
}



export const calculateDeduction = (invoiceLine, option) => {
  const { percentage, limit } = getDeductionPercentage(option);
  const deductionAmount = invoiceLine.price * percentage / 100;
  if (limit && deductionAmount > limit) {
    return invoiceLine.price - limit;
  }
  return invoiceLine.price - deductionAmount;
}



export const handleRotRutCheckbox = (id, invoiceLines, setInvoiceLines) => {
  setInvoiceLines(
    invoiceLines.map((line) =>
      line._id === id
        ? {
          ...line,
          rotRutSelected: !line.rotRutSelected,
          rotRutOption: !line.rotRutSelected ? '' : line.rotRutOption,
        }
        : line
    )
  );
};




export const handleRotRutOption = (id, option, invoiceLines, setInvoiceLines) => {
  setInvoiceLines(
    invoiceLines.map((line) =>
      line._id === id ? { ...line, rotRutOption: option } : line
    )
  );
}






export const handleRotRutDiscount = (invoiceLine, rotRutSelected, rotRutOption) => {
  if (rotRutSelected && rotRutOption) {
    const discountAmount = calculateDeduction(invoiceLine, rotRutOption);
    return discountAmount;
  }
  return invoiceLine.price;
}

// export const getDeduction = (invoiceLine) => {
//   if (invoiceLine.rotRutOption) {
//     const { percentage, limit } = getDeductionPercentage(invoiceLine.rotRutOption);
//     const deductionAmount = invoiceLine.quantity * invoiceLine.sellingPrice * percentage / 100;
//     if (limit && deductionAmount > limit) {
//       return limit;
//     }
//     return deductionAmount;
//   }
//   return 0;
// }


// export const calculateDiscount = (invoiceLine) => {
//   if (invoiceLine.rotRutSelected && invoiceLine.rotRutOption) {
//     const discountPercentage = getDeductionPercentage(invoiceLine.rotRutOption);
//     const discountAmount = invoiceLine.price * discountPercentage / 100;
//     return invoiceLine.price - discountAmount;
//   }
//   return invoiceLine.price;
// };



export const getDeduction = (invoiceLine) => {
  const materialName = (invoiceLine.name ?? '').toLowerCase();
  const allowAllDeductions = materialName.includes('worked hour');

  if (invoiceLine.rotRutOption && (allowAllDeductions || ['Solcellsinstallation', 'Lagring av egenproducerad elenergi', 'Laddningspunkt elfordon'].includes(invoiceLine.rotRutOption))) {
    const { percentage, limit } = getDeductionPercentage(invoiceLine.rotRutOption);
    const deductionAmount = invoiceLine.quantity * invoiceLine.sellingPrice * percentage / 100;
    if (limit && deductionAmount > limit) {
      return limit;
    }
    return deductionAmount;
  }
  return 0;
}

// Modify the calculateDiscount function
export const calculateDiscount = (invoiceLine) => {
  if (invoiceLine.rotRutSelected && invoiceLine.rotRutOption) {
    const discountPercentage = getDeductionPercentage(invoiceLine.rotRutOption);
    const discountAmount = invoiceLine.price * discountPercentage / 100;
    return invoiceLine.price - discountAmount;
  }
  return invoiceLine.price;
};


export const options = [
  'Solcellsinstallation',
  'Lagring av egenproducerad elenergi',
  'Laddningspunkt elfordon',
  'Murning',
  'Glas/plåtarbete',
  'Mark/dräneringsarbete',
  'Bygg',
  'Målnings/tapetsering',
  'VVS',
  'EL',
  'Städning',
  'Hemtextil och klädvård',
  'Matlagning',
  'Snöskottning',
  'Trädgårdsarbete',
  'Barnpassning',
  'Annan omsorg',
  'Läxhjälp',
  'IT-Tjänster',
  'Tillsyn bostad',
  'Möblering',
  'Transporttjänster',
  'Tvätt och vård av kläder',
];

