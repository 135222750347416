import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const AddUpdateFacilityValidationSchema = (t) => {
  return Joi.object({
    name: Joi.string()
      .label(t("name"))
      .required()
      .messages(
        handleMassage(
          t("name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    customer: Joi.string()
      .required()
      .label(t("customer"))
      .messages(
        handleMassage(
          t("customer"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    address: Joi.string()
      .label(t("address"))
      .required()
      .messages(
        handleMassage(
          t("address"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    city: Joi.string()
      .label(t("city"))
      .required()
      .messages(
        handleMassage(
          t("city"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    postalNo: Joi.number()
      .label(t("postal_no"))
      .required()
      .messages(
        handleMassage(
          t("postal_no"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
  });
};

export { AddUpdateFacilityValidationSchema };
