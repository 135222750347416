import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createProject,
  updateProject,
} from "../../redux/actions/projectActions";
import { customerList } from "../../redux/actions/customersActions";
import { useLocation, useNavigate } from "react-router-dom";
import { AddUpdateProjectValidationSchema } from "./projectValidationSchema";
import { useTranslation } from "react-i18next";

const AddEditProjectComponent = () => {
  const [type, setType] = useState("");
  const [customer, setCustomer] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [hours, setHours] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [wholesaleLock, setWholesaleLock] = useState("");
  const [designCost, setDesignCost] = useState("");
  const [laborCost, setLaborCost] = useState("");
  const [materialCost, setMaterialCost] = useState("");
  const [euCost, setEuCost] = useState("");
  const [otherExpenses, setOtherExpenses] = useState("");
  const [amount, setAmount] = useState("");
  const [billedViaProject, setBilledViaProject] = useState("");
  const [fortnox, setFortnox] = useState("");
  const [fortnoxId, setFortnoxId] = useState("");
  const [allCustomers, setAllCustomers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const formDataToBeValidated = {
      type,
      customer,
      name,
      startDate,
      endDate,
      status,
      // fortnox
    };
    const { error } = AddUpdateProjectValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });

      return;
    }
    const payload = {
      type,
      customer,
      contactPerson,
      name,
      startDate,
      endDate,
      hours,
      projectManager,
      wholesaleLock,
      amount: {
        designCost,
        laborCost,
        euCost,
        materialCost,
        otherExpenses,
        totalAmount: amount,
      },
      billedViaProject,
      fortnox,
      status,
    };

    console.log("DATA SENT => ", payload);

    if (isEdit) {
      const data = await updateProject(state?.projectData?._id, payload);
      if (data.status) {
        toast.success(t("project_updated_successfully"));
        // navigate("/dashboard/project-listing");
      }
    } else {
      const data = await createProject(payload);
      if (data.status) {
        toast.success(t("project_created_successfully"));
        // navigate("/dashboard/project-listing");
      }
    }
  };

  const handleBillRadioChange = (event) => {
    setBilledViaProject(event.target.id);
  };

  const handleFortnoxRadioChange = (event) => {
    setFortnox(event.target.id);
  };

  const handleTypeRadioChange = (event) => {
    setType(event.target.id);
  };

  const getCustomersList = async () => {
    const { data } = await customerList();
    setAllCustomers(data || []);
  };

  useEffect(() => {
    getCustomersList();
  }, []);

  useEffect(() => {
    const data = state?.projectData;
    if (data) {
      setIsEdit(true);
      setType(data.type || "");
      setCustomer(data.customer?._id || "");
      setContactPerson(data.contactPerson || "");
      setName(data.name || "");
      setStartDate(data.startDate.substring(0, 10));
      setEndDate(data.endDate.substring(0, 10));
      setStatus(data.status || "");
      setHours(data.hours);
      setProjectManager(data.projectManager);
      setWholesaleLock(data.wholesaleLock);
      setDesignCost(data.amount?.designCost || "");
      setLaborCost(data.amount?.laborCost || 0);
      setMaterialCost(data.amount?.materialCost || "");
      setEuCost(data.amount?.euCost || "");
      setOtherExpenses(data.amount?.otherExpenses || "");
      setAmount(data.amount?.totalAmount || "");
      setBilledViaProject(data.billedViaProject || "");
      setFortnox(data.fortnox || "");
    }
  }, [state]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("project")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="row">
                <div className="col-lg-4 awen-form">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("type")}</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label={t("main_project")}
                        name="typeGroup2"
                        id="Main project"
                        className="mx-1"
                        value={type}
                        onChange={handleTypeRadioChange}
                        checked={type === "Main project"}
                      />
                      <Form.Check
                        type="radio"
                        label={t("sub_project")}
                        name="typeGroup3"
                        id="Sub project"
                        value={type}
                        onChange={handleTypeRadioChange}
                        checked={type === "Sub project"}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("customer")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setCustomer(e.target.value)}
                      value={customer}
                    >
                      <option value={""} selected>
                        Select customer
                      </option>
                      {allCustomers?.map((c) => (
                        <option value={c._id}>{c.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("contact_person")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("name")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("start_date")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        value={startDate}
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("end_date")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={endDate}
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("status")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option value={""} selected>
                        Select status
                      </option>
                      <option value={"In progress"}>In Progress</option>
                      <option value={"Completed"}>Completed</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("hours")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={hours}
                        type="time"
                        onChange={(e) => setHours(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("project_manager")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setProjectManager(e.target.value)}
                      value={projectManager}
                    >
                      <option value={""} selected>
                        project manager
                      </option>
                      <option value={"In Progress"}>abc</option>
                      <option value={"Completed"}>def</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("wholesale_locks")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setWholesaleLock(e.target.value)}
                      value={wholesaleLock}
                    >
                      <option value={""} selected>
                        Wholesale locks
                      </option>
                      <option value={"In Progress"}>uhdoichoid</option>
                      <option value={"Completed"}>idhoi</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("design_cost")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={designCost}
                        type="text"
                        onChange={(e) => setDesignCost(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("labor_cost")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={laborCost}
                        type="text"
                        onChange={(e) => setLaborCost(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("material_cost")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={materialCost}
                        type="text"
                        onChange={(e) => setMaterialCost(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("eu_cost")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={euCost}
                        type="text"
                        onChange={(e) => setEuCost(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("other_expenses")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={otherExpenses}
                        type="text"
                        onChange={(e) => setOtherExpenses(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("amount")}</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={amount}
                        type="text"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4 awen-form">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("billed_via_project")}</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label={t("yes")}
                        name="radioGroup"
                        id="yes"
                        className="mx-1"
                        value={billedViaProject}
                        onChange={handleBillRadioChange}
                        checked={billedViaProject === "yes"}
                      />
                      <Form.Check
                        type="radio"
                        label={t("no")}
                        name="radioGroup"
                        id="no"
                        value={billedViaProject}
                        onChange={handleBillRadioChange}
                        checked={billedViaProject === "no"}
                      />
                    </div>
                  </Form.Group>
                </div>
                {type === "Main project" && (
                  <div className="col-lg-4 awen-form">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("what_to_send_to_fortnox")}</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          label={t("main_project")}
                          name="radioGroup2"
                          id="Main project"
                          className="mx-1"
                          value={fortnox}
                          onChange={handleFortnoxRadioChange}
                          checked={fortnox === "Main project"}
                        />
                        <Form.Check
                          type="radio"
                          label={t("sub_project")}
                          name="radioGroup2"
                          id="Sub project"
                          value={fortnox}
                          onChange={handleFortnoxRadioChange}
                          checked={fortnox === "Sub project"}
                        />
                      </div>
                    </Form.Group>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Button className="simple-btn mx-auto" onClick={handleSubmit}>
                  {isEdit ? t("update") : t("submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditProjectComponent;
