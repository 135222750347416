import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createOrganization = async (data) => {
	try {
		const res = await request.post(Constants.create_organization_api_url, data);
		if (res.data?.status) {
			return res.data;
		}
		else { toast.error(res.data.message); }
	} catch (e) {
		toast.error(e.message);
	}
};

export const organizationList = async () => {
	try {
		const res = await request.get(Constants.organization_list_api_url);
		if (res) {
			return res.data;
		}
	} catch (e) {
		toast.error(e.message);
	}
};

export const updateOrganization = async (_id, organization) => {
	try {
		const res = await request.post(Constants.update_organiation_api_url, {
			_id,
			organization,
		});
		if (res) {
			return res.data;
		}
	} catch (e) {
		toast.error(e.message);
	}
};
