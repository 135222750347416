import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { selfControlList } from "../../../redux/actions/selfControlActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selfControlListValidationSchema } from "../selfControlValidationSchema";
import { selectedSelfControlList } from "../../../redux/slices/selfControlListSlice";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import {
  createSelfControlLists,
  updateSelfControlLists,
} from "../../../redux/actions/selfControlListActions";
import "../selfControl.scss"

const MultiSelect = ({ options, selectedOptions, onChange }) => {
  return (
    <Select
      options={options}
      value={selectedOptions}
      onChange={onChange}
      isMulti
    />
  );
};


const AddEditSelfControlListComponent = () => {
  const [name, setName] = useState("");
  const [points, setPoints] = useState(0);
  const [selfControlsPoint, setSelfControlsPoint] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { state } = useLocation();
  const { t } = useTranslation();
  const [existingPointsIds, setExistingPointsIds] = useState(state?.selfControlDataList?.points.map((s) => { return s.selfControlId?._id }));
  const selfcontrolListData = state?.selfControlDataList;
  const [selectedPoints, setSelectedPoints] = useState(selfcontrolListData?.points);

  // const handleMultiSelectChange = (selected) => {
  //   setSelectedPoints(selected);
  // }; 

  const handleSubmit = async () => {
    if (!name) {
      return toast.error("Please enter name")
    }
    // if (!selectedPoints?.length) {
    //   return toast.error("Please select points")
    // }
    const payload = {
      name,
      points: selectedPoints.map((e) => ({ selfControlId: e?.selfControlId, point: e?.point })),
    };
    if (isEdit) {
      const data = await updateSelfControlLists(
        selfcontrolListData?._id,
        payload
      );
      if (data.status) {
        toast.success(t("self_control_list_updated_successfully"));
        dispatch(selectedSelfControlList(data?.data))
      }
    } else {
      const data = await createSelfControlLists(payload);
      if (data.status) {
        toast.success(t("self_control_list_created_successfully"));
      }
    }
  };

  const getSelfControlList = async () => {
    const selfControlData = await selfControlList();
    if (selfControlData?.status) {
      setSelfControlsPoint(selfControlData?.data);
    }
  };

  const handleCheckboxClick = (controlPoint) => {
    if (existingPointsIds?.includes(controlPoint?._id)) {
      const points1 = selfcontrolListData?.points;
      const filteredPoints = points1?.filter((p) => (p.selfControlId._id !== controlPoint._id))
      const updatedExistingIds = existingPointsIds?.filter((e) => (e !== controlPoint._id))
      setExistingPointsIds(updatedExistingIds)
      setSelectedPoints(filteredPoints)
    } else {
      existingPointsIds?.length ? setExistingPointsIds([...existingPointsIds, controlPoint?._id]) : setExistingPointsIds([controlPoint?._id])
      selectedPoints?.length ? setSelectedPoints([...selectedPoints, { selfControlId: controlPoint?._id, point: controlPoint?.points }]) : setSelectedPoints([{ selfControlId: controlPoint?._id, point: controlPoint?.points }])
    }
  }

  useEffect(() => {
    getSelfControlList();
  }, []);

  useEffect(() => {
    const data = selfcontrolListData;
    if (data) {
      setIsEdit(true);
      setPoints(data.points || "");
      setName(data.name || "");
    }
  }, [state]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("self_control_list")} </h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="">
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("name")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="d-flex flex-wrap my-2">
                  {selfControlsPoint?.map((s) => (

                    <div key={s._id} className="col-md-4 selfControls d-flex align-items-center">
                      <span className="p-1" style={{ fontSize: '12px' }}>{s.points}</span>
                      <input type="checkbox" checked={existingPointsIds?.includes(s._id) && true} style={{ width: '11px' }} onClick={() => handleCheckboxClick(s)} />
                    </div>
                  ))}
                </div>
                {/* <div className="col-lg-4">
                  <div>
                    <p>{t("points")}</p>
                    <MultiSelect
                      options={selfControlsPoint.map((e) => ({ value: e._id, label: e.points }))}
                      selectedOptions={selectedPoints}
                      onChange={handleMultiSelectChange}
                    />
                    <div>
                      {selectedPoints.map((option) => (
                        <span key={option._id}>{option.points}</span>
                      ))}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="mt-4">
                <Button className="simple-btn mx-auto" onClick={handleSubmit}>
                  {isEdit ? t("update") : t("submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditSelfControlListComponent;
