import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { facilityList } from "../../redux/actions/facilityActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function ProjectListing() {
  const [facilities, setFacilities] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      name: <div className="table-content">Name</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-facility", {
              state: { facilityData: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Customer</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-facility", {
              state: { facilityData: row },
            });
          }}
        >
          {row.customer?.name}
        </div>
      ),
    },
    {
      name: <div className="table-content">Address</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-facility", {
              state: { facilityData: row },
            });
          }}
        >
          {row.address}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Postal No</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-facility", {
              state: { facilityData: row },
            });
          }}
        >
          {row.postalNo}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">City</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-facility", {
              state: { facilityData: row },
            });
          }}
        >
          {row.city}
        </div>
      ),
      wrap: true,
    },
  ];

  const getFacilitiesList = async () => {
    const facilityData = await facilityList();
    if (facilityData?.status) {
      setFacilities(facilityData?.data);
    }
  };

  useEffect(() => {
    getFacilitiesList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>Facilities</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <form action="" className="header-search">
            <input type="text" placeholder="Search" />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-facility" className="simple-btn">
            Add Facility
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          pagination
          data={facilities}
          responsive={true}
          paginationPerPage={40}
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
        />
      </div>
    </div>
  );
}
