import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const AddUpdateWorkorderValidationSchema = (t) => {
  return Joi.object({
    customer: Joi.string()
      .required()
      .label(t("customer"))
      .messages(
        handleMassage(
          t("customer"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    email: Joi.string()
      .label(t("email"))
      .required()
      .messages(
        handleMassage(
          t("email"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    contactPerson: Joi.string()
      .label(t("contact_person"))
      .required()
      .messages(
        handleMassage(
          t("contact_person"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    zipCode: Joi.number()
      .label(t("zip_code"))
      .messages(
        handleMassage(
          t("zip_code"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    phoneNumber: Joi.number()
      .label(t("phone_number"))
      .required()
      .messages(
        handleMassage(
          t("phone_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    fixedPrice: Joi.number()
      .label(t("fixed_price"))
      .required()
      .messages(
        handleMassage(
          t("fixed_price"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    priority: Joi.string()
      .label(t("priority"))
      .required()
      .messages(
        handleMassage(
          t("priority"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    status: Joi.string()
      .label(t("status"))
      .required()
      .messages(
        handleMassage(
          t("status"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

const AddArticleValidationSchema = (t) => {
  return Joi.object({
    itemNumber: Joi.number()
      .required()
      .label(t("article_number"))
      .messages(
        handleMassage(
          t("article_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    articleName: Joi.string()
      .label(t("article_name"))
      .required()
      .messages(
        handleMassage(
          t("article_name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    quantity: Joi.number()
      .required()
      .label(t("quantity"))
      .messages(
        handleMassage(
          t("quantity"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    unit: Joi.string()
      .required()
      .label(t("unit"))
      .messages(
        handleMassage(
          t("unit"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    // entryPrice: Joi.number()
    //   .required()
    //   .label(t("entry_price"))
    //   .messages(
    //     handleMassage(
    //       t("entry_price"),
    //       t("is_a_required"),
    //       t("should_be_a_type_of_'number'")
    //     )
    //   ),
    // priceOut: Joi.number()
    //   .required()
    //   .label(t("price_out"))
    //   .messages(
    //     handleMassage(
    //       t("price_out"),
    //       t("is_a_required"),
    //       t("should_be_a_type_of_'number'")
    //     )
    //   ),
  });
};

const AddUeValidationSchema = (t) => {
  return Joi.object({
    ueInvoiceNo: Joi.string()
      .label(t("invoice_number"))
      .messages(
        handleMassage(
          t("invoice_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    ueMaterialCost: Joi.string()
      .label(t("material_cost"))
      .messages(
        handleMassage(
          t("material_cost"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    ueLaborCost: Joi.string()
      .label(t("labor_cost"))
      .messages(
        handleMassage(
          t("labor_cost"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    ueSurcharge: Joi.string()
      .label(t("surcharge"))
      .messages(
        handleMassage(
          t("surcharge"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

const AddMaterialValidationSchema = (t) => {
  return Joi.object({
    materialWholesale: Joi.string()
      .label(t("wholesale"))
      .required()
      .messages(
        handleMassage(
          t("wholesale"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    // materialName: Joi.string()
    //   .label(t("item_name"))
    //   .required()
    //   .messages(
    //     handleMassage(
    //       t("item_name"),
    //       t("is_a_required"),
    //       t("should_be_a_type_of_'text'")
    //     )
    //   ),
    // materialItemNumber: Joi.string()
    //   .label(t("item_number"))
    //   .messages(
    //     handleMassage(
    //       t("item_number"),
    //       t("is_a_required"),
    //       t("should_be_a_type_of_'text'")
    //     )
    //   ),
    materialUnit: Joi.string()
      .label(t("unit"))
      .messages(
        handleMassage(
          t("unit"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    materialQty: Joi.number()
      .label(t("quantity"))
      .required()
      .messages(
        handleMassage(
          t("quantity"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    materialAward: Joi.number()
      .label(t("purchase_price"))
      .messages(
        handleMassage(
          t("purchase_price"),
          t("is_a_required"),
          t("should_be_a_type_of_'nuumber'")
        )
      ),
    materailVAT: Joi.number()
      .label(t("selling_price"))
      .messages(
        handleMassage(
          t("selling_price"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
  });
};

const timeReportValidationSchema = (t) => {
  return Joi.object({
    drivingAllowanceUOA: Joi.number()
      .label(t("driving_allowance_UOA"))
      .messages(
        handleMassage(
          t("driving_allowance_UOA"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    drivingAllowanceIOA: Joi.number()
      .label(t("driving_allowance_IOA"))
      .messages(
        handleMassage(
          t("driving_allowance_IOA"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    restidIOA: Joi.number()
      .label(t("restid_IOA"))
      .messages(
        handleMassage(
          t("restid_IOA"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    restidUOA: Joi.number()
      .label(t("restid_UOA"))
      .messages(
        handleMassage(
          t("restid_UOA"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
  });
};

export {
  AddUpdateWorkorderValidationSchema,
  AddArticleValidationSchema,
  AddUeValidationSchema,
  AddMaterialValidationSchema,
  timeReportValidationSchema,
};
