import React, { useEffect, useState } from "react";
import { getStaffWorkSchedules } from "../../redux/actions/workScheduleActions";
import "./WorkSchedule.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import DatedTimereport from "./DatedTimereports";
import { HOURS, MINUTES, deviationTypes } from "../../constants/Contants";
import { Tooltip, OverlayTrigger, Form, Button } from "react-bootstrap";
import { createWorkorderAttributes } from "../../redux/actions/workorderActions";
import classNames from "classnames";
import { toast } from "react-toastify";
import moment from "moment";

const WorkScheduleComponent = () => {
  const [month, setMonth] = useState(new Date());
  const [monthDates, setMonthDates] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [workOrderId, setWorkOrderId] = useState("")
  const [userId, setUserId] = useState("")
  const [endDate, setEndDate] = useState(new Date());
  const [hourMinutes, setHourMinutes] = useState({
    startHour: "00", startMinute: "00", endHour: "00", endMinute: "00"
  });
  const [calculatedTime, setCalculatedTime] = useState("00:00");
  const [datedTimereports, setDatedTimereports] = useState([]);
  const [showDatedTable, setShowDatedTable] = useState(false);
  const [hoursAndDate, setHoursAndDate] = useState({})
  const [selectedDeviation, setSelectedDeviation] = useState("")

  const renderTooltip = (props, content) => (
    <Tooltip id="custom-tooltip" {...props}>
      <div className="main">
        {/* <h3>Schedule Time</h3>
                <p>{content.hours || '07:00 to 16:00'}</p> */}

        <h3>Report Time</h3>
        <p>{content.hours || "07:00 to 16:00"}</p>

        <h3>Personal</h3>
        <p>{content.user || "Person, William"}</p>

        <h3>Customer</h3>
        <p>{content.customer || "ABC"}</p>

        <h3>Work Order</h3>
        <p>{content.workOrder || "000000000"}</p>

        <h3>Information</h3>
        <p>{content.info || "Additional details..."}</p>

        <h3>Personal Note</h3>
        <p>{content.note || "Notes here..."}</p>
      </div>
    </Tooltip>
  );

  function getDatesAndDays(year, month) {
    const dates = [];
    const days = [];
    const totalDays = new Date(year, month, 0).getDate();
    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(year, month - 1, day);
      const formattedDate = day.toString().padStart(2, "0");
      const formattedDay = date
        .toLocaleString("en-US", { weekday: "short" })
        .toUpperCase()
        .slice(0, 2);

      dates.push(formattedDate);
      days.push(formattedDay);
    }
    console.log("dates", dates);
    setMonthDates(dates);
    setMonthDays(days);
  }

  const getPreviousMonthDate = () => {
    const year = month.getFullYear();
    const mon = month.getMonth();
    const previousMonthDate = new Date(year, mon - 1, 1);
    setMonth(previousMonthDate);
  };

  const getNextMonthDate = () => {
    const dateObj = month;
    const year = dateObj.getFullYear();
    const mon = dateObj.getMonth() + 1;
    const nextMonthDate = new Date(year, mon, 1);
    setMonth(nextMonthDate);
  };

  const handleGetStaffWorkSchedules = async () => {
    const data = await getStaffWorkSchedules({
      month: month.getMonth() + 1,
      year: month.getFullYear(),
    });
    setTableData(data?.data);
    setAllStaff(data?.data);
  };

  const assignColor = (hours) => {
    const time = hours.split(":")[0];
    return +time === 0 ? "" : +time === 8 ? "bg-green" : +time > 8 && "bg-blue";
  };
  
  const handleAddDeviation = async() => {
    if(!selectedDeviation) {
     return  toast.error("Please select deviation type!")
    }
    const payload = {
      date: startDate,
      start: startDate,
      stop: startDate,
      totalHours: isHoliday ? "08:00" : calculatedTime,
      user: userId,
      isDeviation: true,
      deviationType: selectedDeviation
    }
    const data = await createWorkorderAttributes(
      workOrderId,
      payload,
      "timeReports"
    );
    if(data?.status) {
      handleGetStaffWorkSchedules();
      toast.success("Deviation added successfully")
    }
  }

  const handleTimeChange = (e, type) => {
    const newHourMinutes = { ...hourMinutes, [type]: e.target.value };
    setHourMinutes(newHourMinutes);
    calculateTimeDifference(newHourMinutes);
  };
  
  const calculateTimeDifference = (hourMinutes) => {
    const { startHour, startMinute, endHour, endMinute } = hourMinutes;
    const startTime = new Date(`${moment(startDate).format('YYYY-MM-DD')}T${startHour.padStart(2, "0")}:${startMinute.padStart(2, "0")}:00`);
    const endTime = new Date(`${moment(startDate).format('YYYY-MM-DD')}T${endHour.padStart(2, "0")}:${endMinute.padStart(2, "0")}:00`);
  
    if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
      alert("Invalid time format. Please check your inputs.");
      setCalculatedTime("00:00");
      return;
    }
    const timeDifferenceMs = endTime - startTime;
    if (timeDifferenceMs < 0) {
      alert("End time cannot be earlier than start time!");
      setCalculatedTime("00:00");
      return;
    }
    const totalMinutes = Math.floor(timeDifferenceMs / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
  
    setCalculatedTime(formattedTime);
  };

  const getRecordOnDate = (t) => {
    const filteredData = allStaff.map((a) => {
      const filteredDates = a.dates.filter((d) => d.date === t.date);
      return { ...a, dates: filteredDates };
    });
    setDatedTimereports(filteredData)
    setHoursAndDate(t)
  }

  useEffect(() => {
    handleGetStaffWorkSchedules();
    getDatesAndDays(month.getFullYear(), month.getMonth() + 1);
  }, []);

  useEffect(() => {
    handleGetStaffWorkSchedules();
    getDatesAndDays(month.getFullYear(), month.getMonth() + 1);
  }, [month]);

  useEffect(() => {
    if (selectedStaff) {
      const data = allStaff.filter((t) => t.userDetails.name === selectedStaff);
      setTableData(data);
      setWorkOrderId(data[0]?.workOrderId)
      setUserId(data[0]?.userDetails?._id)
    } else {
      setTableData(allStaff);
    }
  }, [selectedStaff]);

  const [isHoliday, setIsHoliday] = useState(false);

  const handleHolidayChange = (event) => {
    setIsHoliday(event.target.value === "holiday");
  };


  return (
    <>
      {showDatedTable && 
      <div className="mx-5 my-3">
        <DatedTimereport data={datedTimereports} hoursAndDate={hoursAndDate}/>
      </div>
      }
      <table className="schedule table ">
        <tr className="heading-name">
          <th className="heading-1 text-center">Month</th>
          <th colspan="29" className="heading-1 text-center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <div
                className="cursor-pointer"
                onClick={getPreviousMonthDate}
              >{`<< `}</div>
              {`${month.getMonth() + 1} ${month.getFullYear()}`}
              <div
                className="cursor-pointer"
                onClick={getNextMonthDate}
              >{` >>`}</div>
            </div>
          </th>
        </tr>
        <tr className="week-date">
          <td rowspan="2" className="heading-1 ">
            Day
          </td>
          {monthDates.map((d) => (
            <td className="heading-date">{d}</td>
          ))}
        </tr>
        <tr className="week-day">
          {monthDays.map((d) => (
            <td className={`heading-date ${d === "SU" && "custom-red"}`}>
              {d}
            </td>
          ))}
        </tr>
        {tableData?.map((e) => (
          <tr>
            <td className="heading">{e.userDetails.name}</td>
            {e.dates.map((h) => (
              <OverlayTrigger
                placement="bottom"
                overlay={(props) =>
                  renderTooltip(props, {
                    hours: h.totalHours,
                    user: e.userDetails.name,
                    workOrder: e.aoname,
                    customer: e.customerName,
                  })
                }
              >
                <td className={`table-light ${h.isDeviation ? "bg-yellow" : assignColor(h.totalHours)} cursor-pointer`} onClick={() => {getRecordOnDate(h); setShowDatedTable(!showDatedTable)}}>
                  {h.totalHours}
                </td>
              </OverlayTrigger>
            ))}
          </tr>
        ))}
      </table>

      <div className="actionBar mt-3">
        {selectedStaff && (
          <>
            <div className="actionHeader">
              <p>
                Add discrepancy for <span>{selectedStaff}</span>
              </p>
            </div>
            <div className="actionBody">
              <Form>
                <div className="holiday-leave">
                  <div className="reason d-flex">
                    <Form.Select aria-label="Default select example"
                       onChange={(e) => setSelectedDeviation(e.target.value)}
                    >
                      <option value="">SELECT DEVIATION TYPE</option>
                      {deviationTypes?.map((c) => (
                        <option value={c.value}>{c.name}</option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <Form.Group
                      className="date start-date"
                      controlId="formGroupEmail"
                    >
                      <Form.Control
                        type="date"
                        placeholder="From"
                        value={moment(startDate).format("YYYY-MM-DD")} // Use YYYY-MM-DD format
                        onChange={(e) => setStartDate(new Date(e.target.value))} // Convert string to Date object
                      />
                    </Form.Group>
                    <h3 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                      To
                    </h3>
                    <Form.Group
                      className="date end-date"
                      controlId="formGroupPassword"
                    >
                      <Form.Control type="date" placeholder="To" 
                       value={moment(endDate).format("YYYY-MM-DD")} // Use YYYY-MM-DD format
                       onChange={(e) => setEndDate(new Date(e.target.value))} // Convert string to Date object
                      />
                    </Form.Group>
                  </div>
                  <div className="holiday">
                    <Form.Select
                      id="holidaySelection"
                      onChange={handleHolidayChange}
                    >
                      <option value="hours">HOURS</option>
                      <option value="holiday">HOLIDAY</option>
                    </Form.Select>
                  </div>

                  {/* Time Inputs Section */}
                  <div
                    className={classNames(
                      "d-flex align-items-center justify-content-center gap-3 time-input-section",
                      {
                        "disabled-time": isHoliday,
                      }
                    )}
                  >
                    <div className="d-flex gap-1 align-items-center">
                      <Form.Select
                        className="mb-0"
                        id="startHours"
                        disabled={isHoliday}
                        onChange={(e) => handleTimeChange(e,"startHour")}
                      >
                        {HOURS?.map((c) => (
                          <option value={c}>{c}</option>
                        ))}
                      </Form.Select>
                      <span>:</span>
                      <Form.Select id="startMins" 
                       disabled={isHoliday}
                       onChange={(e) => handleTimeChange(e,"startMinute")}
                      >
                        {MINUTES?.map((c) => (
                          <option value={c}>{c}</option>
                        ))}
                      </Form.Select>
                    </div>
                    <h3 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                      To
                    </h3>
                    <div className="d-flex gap-1 align-items-center">
                      <Form.Select
                        className="mb-0"
                        id="endHours"
                        disabled={isHoliday}
                        onChange={(e) => handleTimeChange(e,"endHour")}
                      >
                        {HOURS?.map((c) => (
                          <option value={c}>{c}</option>
                        ))}
                      </Form.Select>
                      <span>:</span>
                      <Form.Select id="endMins" 
                       disabled={isHoliday}
                       onChange={(e) => handleTimeChange(e,"endMinute")}
                       >
                        {MINUTES?.map((c) => (
                          <option value={c}>{c}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>

                  <div>
                    <p className="mb-0">{calculatedTime}</p>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
      <div className="d-flex m-3" style={{ gap: "20px" }}>
        <div
          className="d-flex"
          style={{
            margin: "0px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Form.Label style={{ margin: "0px", width: "100px" }}>
            Select Staff
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(d) => setSelectedStaff(d.target.value)}
            style={{ width: "200px" }}
          >
            <option value="">Select staff</option>
            {allStaff?.map((u) => (
              <option value={u.userDetails.name}>{u.userDetails.name}</option>
            ))}
          </Form.Select>
        </div>
        {selectedStaff && (
          <div>
            <Button
              className="simple-btn mx-auto m-2"
              onClick={handleAddDeviation}
            >
              {"save"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkScheduleComponent;