import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const machineValidationSchema = (t) => {
  return Joi.object({
    name: Joi.string()
      .label(t("name"))
      .required()
      .messages(
        handleMassage(
          t("name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    regNumber: Joi.number()
      .label(t("registration_number"))
      .messages(
        handleMassage(
          t("registration_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    internalNumber: Joi.number()
      .label(t("internal_number"))
      .messages(
        handleMassage(
          t("internal_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
  });
};

export { machineValidationSchema };
