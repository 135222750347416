
import './App.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import Routes from "../src/Routes/index"

function App() {
  return (
    <div>
      <ToastContainer autoClose={5000} />
      <Routes />
    </div>
  );
}

export default App;
