import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function calculateTimeDifference(timeObj) {
  const startHour = parseInt(timeObj.startHour, 10);
  const startMinute = parseInt(timeObj.startMinute, 10);
  const endHour = parseInt(timeObj.endHour, 10);
  const endMinute = parseInt(timeObj.endMinute, 10);

  const startTime = startHour * 60 + startMinute;
  const endTime = endHour * 60 + endMinute;

  const totalMinutes = endTime - startTime;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours === 0 && minutes === 0) {
    return "";
  }

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
}

const UeColumns = () => {
  const { t } = useTranslation();
  return [
    {
      name: <div className="table-content">{t("invoice_number")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.invoiceNo}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("material_cost")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.materialCost}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("labor_cost")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.laborCost}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("surcharge")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("Total")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">
          {+row.materialCost + +row.laborCost}
        </div>
      ),
      wrap: true,
    },
  ];
};

const TimeReportColumns = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userData?.value);
  const selectedStaff = `${user?.first_name} ${user.last_name}`;

  return [
    {
      name: <div className="table-content">{t("date")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          {new Date(row.date).toLocaleDateString()}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("start")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row.clockTime ? new Date(row.start).toLocaleString() : null}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("stop")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row.clockTime ? new Date(row.stop).toLocaleString() : null}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("staff")}</div>,
      selector: (row) => <div className="jobPosition">{selectedStaff}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("notes")}</div>,
      selector: (row) => <div className="jobPosition">{row.note}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("day")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row.routineHours
            ? row.routineHours
            : Number(row.totalHours) > 10
              ? "10"
              : row.totalHours
          }
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OB1</div>,
      selector: (row) => <div className="jobPosition">{row.surcharge}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">Breaks</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row.cases?.length ? calculateTimeDifference(row.cases[0]) : ""}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT1</div>,
      selector: (row) => (
        <div className="jobPosition">
          {row.overtime !== ""
            ? row.overtime !== "00"
              ? `${row.overtime}:00`
              : ""
            : ""}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT2</div>,
      selector: (row) => (
        <div className="jobPosition">
          {/* {getT2value(row)} */}
          {row.hoursOnWeekend !== ""
            ? row.hoursOnWeekend !== "00"
              ? `${row.hoursOnWeekend}:00`
              : ""
            : ""}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">Driving</div>,
      selector: (row) => (
        <div className="jobPosition">
          {+row.allowance.restidUOA + +row.allowance.restidIOA}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">KM</div>,
      selector: (row) => (
        <div className="jobPosition">
          {+row.allowance.drivingAllowanceUOA +
            +row.allowance.drivingAllowanceIOA}
        </div>
      ),
      wrap: true,
    },
  ];
};

const WorkShiftColumns = () => {
  const { t } = useTranslation();
  return [
    {
      name: <div className="table-content">GA</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.ao}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">ES</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.ao}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("date")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.ao}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("start")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.start}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("stop")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.laborCost}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("personal")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("machine")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("day")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OB1</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OB2</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OB3</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT1</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT2</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT3</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">KM</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("sum")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.surcharge}</div>
      ),
      wrap: true,
    },
  ];
};

const MaterialColumnsRow = (props) => {
  const [editQuantity, setEditQuantity] = useState(false);
  const { t } = useTranslation();

  const [editQuantityValue, setEditQuantityValue] = useState(
    props.row.quantity
  );
  return (
    <>
      <div className="jobPosition d-flex gap-2">
        {editQuantity ? (
          <>
            <form className="d-flex">
              <input
                className="w-75"
                value={editQuantityValue}
                onChange={(e) => {
                  setEditQuantityValue(e.target.value);
                }}
              />
              <button
                className="simple-btn table-edit-btn w-25 p-1 rounded-0"
                type="submit"
                onClick={props.func}
              >
                {t("ok")}
              </button>
            </form>
          </>
        ) : (
          <>
            {props.row.quantity}
            <i
              className="fa fa-edit ms-auto me-3"
              onClick={() => {
                setEditQuantity(true);
              }}
            ></i>
          </>
        )}
      </div>
    </>
  );
};

const MaterialColumns = (props) => {
  const { t } = useTranslation();
  console.log(123, props);
  return [
    {
      name: <div className="table-content">{t("wholesale")}</div>,
      selector: (row) => <div className="jobPosition">{row.wholesale}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("article_number")}</div>,
      selector: (row) => <div className="jobPosition">{row.number}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("article_name")}</div>,
      selector: (row) => <div className="jobPosition">{row.name}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("quantity")}</div>,
      selector: (row) => (
        <MaterialColumnsRow
          row={row}
          func={props.handleAlreadyExistingMAterial}
          mlist={props.materialList}
        />
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("unit")}</div>,
      selector: (row) => <div className="jobPosition">{row.unit}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("purcahse_price")}</div>,
      selector: (row) => <div className="jobPosition">{row.purchasePrice}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("selling_price")}</div>,
      selector: (row) => <div className="jobPosition">{row.sellingPrice}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("Added by")}</div>,
      selector: (row) => <div className="jobPosition">{row.user}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("date")}</div>,
      selector: (row) => (
        <div className="jobPosition">{new Date(row.date).toLocaleString()}</div>
      ),
      wrap: true,
    },
  ];
};

const SelfControlColumns = (navigate) => {
  const { t } = useTranslation();
  return [
    {
      name: <div className="table-content">{t("points")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            navigate("/dashboard/add-self-control", {
              state: { selfControlData: row },
            });
          }}
        >
          {row.points}
        </div>
      ),
      wrap: true,
    },
  ];
};

const SelfControlListColumns = (navigate) => {
  const { t } = useTranslation();
  return [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            navigate("/dashboard/add-self-control-list", {
              state: { selfControlDataList: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
  ];
};

const ResourcesColumn = () => {
  const { t } = useTranslation();
  return [
    {
      name: <div className="table-content">{t("type")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.resourceType}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.name}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("unit_price")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.unitPrice}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("debit_form")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.debitForm}</div>
      ),
      wrap: true,
    },
  ];
};

const TextArticlesColumns = (handleDeleteArticle) => {
  const { t } = useTranslation();
  return [
    {
      name: <div className="table-content">{t("item_number")}</div>,
      selector: (row) => <div className="jobPosition">{row.itemNumber}</div>,
      wrap: true,
    },
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => <div className="jobPosition">{row.name}</div>,
    },
    {
      name: <div className="table-content">{t("quantity")}</div>,
      selector: (row) => <div className="jobPosition">{row.quantity}</div>,
    },
    {
      name: <div className="table-content">{t("unit")}</div>,
      selector: (row) => <div className="jobPosition">{row.unit}</div>,
    },
    {
      name: <div className="table-content">{t("purchase_price")}</div>,
      selector: (row) => <div className="jobPosition">{row.entryPrice}</div>,
    },
    {
      name: <div className="table-content">{t("taken")}</div>,
      selector: (row) => <div className="jobPosition">{row.priceOut}</div>,
    },
    {
      name: <div className="table-content">{t("actions")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
        // onClick={() => {
        //   handleDeleteArticle(row._id);
        // }}
        >
          <FontAwesomeIcon icon={faCancel} />
        </div>
      ),
    },
  ];
};

export {
  UeColumns,
  MaterialColumns,
  WorkShiftColumns,
  SelfControlColumns,
  SelfControlListColumns,
  ResourcesColumn,
  TextArticlesColumns,
  TimeReportColumns,
  calculateTimeDifference,
};
