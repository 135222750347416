import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createStaff = async (data) => {
  try {
    const res = await request.post(Constants.create_staff_api_url, data);
    if (res.data?.status) {
      return res.data;
    }
    else { toast.error(res.data.message); }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateStaff = async (_id, user) => {
  try {
    const res = await request.post(Constants.update_staff_api_url, {
      _id,
      user,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const staffList = async (clientId) => {
  try {
    const res = await request.get(`${Constants.staff_list_api_url}/${clientId}`);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const loginHistoryList = async (email) => {
  try {
    const res = await request.post(Constants.login_history_api_url, email);
    if (res) {
      return res?.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
