import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedWorkorder: null,
  tab: "workOrderInfo"
};

export const slice = createSlice({
  name: "workorderSlice",
  initialState,
  reducers: {
    selectedWorkorder: (state, action) => {
      //Save Selected Workorder In Redux
      state.selectedWorkorder = action.payload;
    },
    allWorkorderList: (state, action) => {
      //Save All Workorders List In Redux
      state.allWorkorderList = action.payload;
    },
    currentTab: (state, action) => {
      state.tab = action.payload
    }
  },
});

export const { selectedWorkorder, allWorkorderList, currentTab } = slice.actions;

export default slice.reducer;
