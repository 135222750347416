import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { createOrganization, updateOrganization } from "../../redux/actions/organizationAction";
import { AddOranizationValidationSchema } from "./OrganizationValidationSchema";

const AddEditOrganizationComponent = () => {
  const [companyName, setCompanyName] = useState("")
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("");
  const [contactPerson, setContactPerson] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const { t } = useTranslation();
  const { state } = useLocation();

  const handleCreateAdmin = async (e) => {
    const formDataToBeValidated = {
      companyName,
      email,
      username,
      password,
    };

    const { error } = AddOranizationValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      console.log(error);
      error.details.forEach((err) => {
        toast.error(err.message);
        console.log(err);
      });
      return;
    }

    const payload = {
      companyName,
      address,
      email,
      phone,
      contactPerson,
      username,
      password,
    };

    try {
      if (isEdit) {
        const data = await updateOrganization(state?.organizationData?._id, payload);
        if (data.status) {
          toast.success(t("organization_updated"));
        }
      } else {
        const data = await createOrganization(payload);
        if (data?.status) {
          toast.success(t("organization_created"));
        }
      }
    } catch (error) {
      console.log("ERROR => ", error);
    }
  };

  useEffect(() => {
    const data = state?.organizationData;
    if (data) {
      setIsEdit(true);
      setCompanyName(data?.companyName)
      setAddress(data.address)
      setPhone(data.phone)
      setUsername(data.username)
      setContactPerson(data.contactPerson)
      setEmail(data.email);
      setPassword(data.password);
    } else {
      setIsEdit(false);
    }
  }, [state]);

  return (
    <div>
      <div className="heading">
        <h3>{t("add_organization")}</h3>
      </div>
      <div className="row m-0 bg-white rounded py-5 px-4">
        <div className="col-md-12 mx-auto mb-0">
          <Form className="form-design">
            <div className="row gx-5">
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("company_name")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter company name..."
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("address")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name..."
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("phone")}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter your phone..."
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("email")}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter your email..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("contact_person")}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter contact person..."
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("username")}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter contact person..."
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>
              </div>
              {isEdit ? (
                ""
              ) : (
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("password")}</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup className="mb-2">
                        <Form.Control
                          type="password"
                          placeholder="Enter your password..."
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </InputGroup>
                  </Form.Group>
                </div>
              )}
            </div>
            <div className="mt-4">
              <Button
                className="simple-btn mx-auto"
                onClick={handleCreateAdmin}
              >
                {t("submit")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditOrganizationComponent;
