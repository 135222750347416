import React from "react";
import DataTable from "react-data-table-component";

const DatedTimereport = ({data, hoursAndDate}) => {
  const columns = [
    {
      name: <div className="table-content">{"DATE"}</div>,
      selector: (row) => ( <div className="jobPosition">{row.dates.find((d) => d.date === hoursAndDate.date).date}</div> ),
      wrap: true,
    },
    {
        name: <div className="table-content">{"WORKING HOURS"}</div>,
        selector: (row) => ( <div className="jobPosition">{row.dates.find((d) => d.date === hoursAndDate.date).totalHours}</div> ),
        wrap: true,
      },
    {
        name: <div className="table-content">{"CUSTOMER"}</div>,
        selector: (row) => ( <div className="jobPosition">{row.customerName}</div> ),
        wrap: true,
      },
      {
        name: <div className="table-content">{"WORK ORDER"}</div>,
        selector: (row) => ( <div className="jobPosition">{row.aoname}</div> ),
        wrap: true,
      },
  ];
  return (
    <div className="customListing">
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          data={data}
          responsive={true}
        />
      </div>
    </div>
  );
};

export default DatedTimereport;
