import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useEffect } from "react";

import { adminList } from "../../redux/actions/authActions";

const AdminList = () => {
  const [adminListContent, setAdminListContent] = useState([]);

  let navigate = useNavigate();
  const { t } = useTranslation();

  const columns = [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-admin", {
              state: { adminListData: row },
            });
          }}
        >
          {row.first_name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("email")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-admin", {
              state: { adminListData: row },
            });
          }}
        >
          {row.email}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("role")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-admin", {
              state: { adminListData: row },
            });
          }}
        >
          {row.role}
        </div>
      ),
      wrap: true,
    },
  ];

  const getAdminList = async () => {
    const adminListData = await adminList();
    if (adminListData?.status) {
      setAdminListContent(adminListData?.data.users);
    }
  };

  useEffect(() => {
    getAdminList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t("admin_list")}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <Link to="/dashboard/add-admin" className="simple-btn">
            {t("add_admin")}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          pagination
          data={adminListContent}
          responsive={true}
          paginationPerPage={40}
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
        />
      </div>
    </div>
  );
};

export default AdminList;
