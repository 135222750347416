import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SimplePlus from "../../../assets/images/simple-plus.svg";
import styledPlus from "../../../assets/images/plus-icon.svg";
import PDFImage from "../../../assets/images/download.png";
import { updateSelfControl } from "../../../redux/actions/selfControlActions";
import {
  deleteSelfControlPoint,
  updateSelfControlLists,
  updateSelfControlListPoint
} from "../../../redux/actions/selfControlListActions";
import { selfControlLists } from "../../../redux/actions/selfControlListActions";
import { useSelector } from "react-redux";
import { updateWorkorder } from "../../../redux/actions/workorderActions";
import { selectedWorkorder } from "../../../redux/slices/workorderSlice";
import { toast } from "react-toastify";
import { fileUpload_Generic } from "../../../redux/actions/uploadActions";
import SelfControlPdf from "../PDFs/SelfControlPdf"
import { PDFViewer } from "@react-pdf/renderer";
import Modal from "react-bootstrap/Modal";
import PdfTypeModal from "../PDFs/PdfsTypeModal";
import CommonButton from "./CommonButton";
const SelfControlTab = ({
  selfControls,
  setSelfControls,
  selectedSelfControlsList,
  setSelectedSelfControlsList,
  selectedSelfControlsListPoints,
  setSelectedSelfControlsListPoints,
  pointCount,
  setPointCount,
  exportInvoice,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userData?.value);
  const selectedWorkorderData = useSelector(
    (state) => state.workorder.selectedWorkorder
  );
  const currentUser = `${user?.first_name} ${user.last_name}`;
  const [selectedFile, setSelectedFile] = useState();
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);

  // const handleSelfControlList = (data) => {
  //   const existingResource = selectedSelfControlsList?.find(
  //     (r) => r._id === data._id
  //   );
  //   if (existingResource) {
  //     setSelectedSelfControlsList(
  //       selectedSelfControlsList.filter((s) => s._id !== data._id)
  //     );
  //     console.log(data.points.length, " point");
  //     setPointCount(pointCount - data.points.length);
  //   } else {
  //     setSelectedSelfControlsList([...selectedSelfControlsList, data]);
  //     console.log(data.points.length, " point");
  //     setPointCount(pointCount + data.points.length);
  //   }
  // };

  // const handleSelfControlListpoint = (data) => {
  //   const existingResource = selectedSelfControlsListPoints?.find(
  //     (r) => r._id === data._id
  //   );
  //   if (existingResource) {
  //     setSelectedSelfControlsListPoints(
  //       selectedSelfControlsListPoints.filter((s) => s._id !== data._id)
  //     );
  //   } else {
  //     setSelectedSelfControlsListPoints([
  //       ...selectedSelfControlsListPoints,
  //       data,
  //     ]);
  //   }
  // };

  const selfControlListColumns = [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => <div className="jobPosition">{row.name}</div>,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.workordersData.find(e => e.workOrder === selectedWorkorderData._id)?.isApproved === true,
      style: {
        backgroundColor: "#a1cfad",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.workordersData.find(e => e.workOrder === selectedWorkorderData._id)?.isFailed === true,
      style: {
        backgroundColor: "#c7797f",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const getRowAttribute = (row, field) => {
    const foundWorkorder = row.workordersData.find(e => e.workOrder === selectedWorkorderData._id);
    const fieldValue = foundWorkorder ? foundWorkorder[field] : undefined;
    return fieldValue;
  }

  const selfControlListPointsColumns = [
    {
      name: <div className="table-content">{t("number")}</div>,
      selector: (row, i) => <div className="jobPosition">{i + 1}</div>,
    },
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => <div className="jobPosition">{row.point}</div>,
    },
    {
      name: <div className="table-content">{t("approved_by")}</div>,
      selector: (row) => <div className="jobPosition">{getRowAttribute(row, "approvedBy")}</div>,
    },
    {
      name: <div className="table-content">{t("time_of_approval")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          {getRowAttribute(row, "time") ? new Date(getRowAttribute(row, "time"))?.toLocaleDateString() : ""}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("notes")}</div>,
      selector: (row) => <div className="jobPosition">{row.point}</div>,
    },
    {
      name: <div className="table-content">{t("attachment")}</div>,
      selector: (
        row,
        index // Add index parameter
      ) => (
        <div className="jobPosition">
          <input
            id={`file-input-${index}`}
            type="file"
            className="file-input__input"
            onChange={(e) => {
              handleAttachment(e.target.files[0], row._id);
              e.target.value = null;
              e.target.files = null;
            }}
          />
          <label className="file-input__label" htmlFor={`file-input-${index}`}>
            {/* Correct htmlFor attribute */}
            <img src={SimplePlus} alt="plus" />
            <span>
              {row.attachments?.find(
                (e) => e.workOrder === selectedWorkorderData?._id
              )?.attachmentName || "Choose a file"}
            </span>
          </label>
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("approved")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          <input
            type="checkbox"
            checked={row.workordersData.find(e => e.workOrder === selectedWorkorderData._id)?.isApproved ? true : false}
            onChange={() => handleApprove(row, !row.workordersData.find(e => e.workOrder === selectedWorkorderData._id)?.isApproved)}
          />
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("failed")}</div>,
      selector: (row) => (
        <div className="jobPosition">
          <input
            type="checkbox"
            checked={row.workordersData.find(e => e.workOrder === selectedWorkorderData._id)?.isFailed ? true : false}
            onChange={() => handleFailed(row, !row.workordersData.find(e => e.workOrder === selectedWorkorderData._id)?.isFailed)}
          />
        </div>
      ),
    },
    {
      name: <div className="table-content"></div>,
      selector: (row) => (
        <div className="jobPosition">
          <Button
            className="bg-danger border-none"
            // className="simple-btn w-5"
            onClick={() => handleDeleteSelfControl(row)}
          >
            <i className="fa fa-trash m-2 fs-5"></i>
          </Button>
        </div>
      ),
    },
  ];

  const handleDeleteSelfControl = async (data) => {
    const confirmed = window.confirm(
      t("Do you want to delete this self control point")
    );
    const payload = {
      selfcontrolListId: selectedSelfControlsList?._id,
      selfcontrolPointID: data?._id,
    };
    if (confirmed) {
      const response = await deleteSelfControlPoint(payload);
      if (response.status) {
        setSelectedSelfControlsList(response?.data);
      }
    }
  };

  const getSelectSelfControl = (id) => {
    const selfControl = selfControls.find((s) => s._id === id);
    setSelectedSelfControlsList(selfControl);
  };
  
  const handleApprove = async (data, approved) => {
    const filterdselfcontrolIndex = selectedSelfControlsList?.points.findIndex(
      (s) => s._id === data?._id
    );
    const filterdselfcontrol = selectedSelfControlsList?.points.find(
      (s) => s._id === data?._id
    );
    const allPoints = selectedSelfControlsList?.points;
    const workorderPointIndex = filterdselfcontrol.workordersData.findIndex(w => w.workOrder === selectedWorkorderData._id)
    if (workorderPointIndex !== -1) {
      const workorderPoint = filterdselfcontrol.workordersData[workorderPointIndex]
      filterdselfcontrol.workordersData[workorderPointIndex] = approved ?
        {
          ...workorderPoint,
          isApproved: approved,
          isFailed: false,
          time: new Date(),
          approvedBy: currentUser,
          workOrder: selectedWorkorderData._id
        } : {
          ...workorderPoint,
          isApproved: approved,
          time: null,
          approvedBy: "",
          workOrder: selectedWorkorderData._id
        }
    } else {
      filterdselfcontrol.workordersData.push({
        isApproved: approved,
        time: new Date(),
        approvedBy: currentUser,
        workOrder: selectedWorkorderData._id
      })
    }
    allPoints[filterdselfcontrolIndex] = filterdselfcontrol;
    //   {
    //     ...filterdselfcontrol,
    //     isApproved: approved,
    //     time: new Date(),
    //     approvedBy: currentUser,
    //   } : {
    //     ...filterdselfcontrol,
    //     isApproved: approved,
    //     time: null,
    //     approvedBy: "",
    //   }

    // const payload = {
    //   listId: selectedSelfControlsList._id,
    //   point: selfControlData,
    //   workorder: selectedWorkorderData._id,
    //   selfControlId: data.selfControlId._id
    // };
    // const updatedSelfControlList = await updateSelfControlListPoint(
    //   payload
    // );
    const payload = {
      ...selectedSelfControlsList,
      points: allPoints,
    };
    const updatedSelfControlList = await updateSelfControlLists(
      selectedSelfControlsList?._id,
      payload
    );
    if (updatedSelfControlList?.status) {
      setSelectedSelfControlsList(updatedSelfControlList?.data);
      toast.success("Self-control updated successfully")
    }
  };

  const handleFailed = async (data, failed) => {
    const filterdselfcontrolIndex = selectedSelfControlsList?.points.findIndex(
      (s) => s._id === data?._id
    );
    const filterdselfcontrol = selectedSelfControlsList?.points.find(
      (s) => s._id === data?._id
    );
    const allPoints = selectedSelfControlsList?.points;
    const workorderPointIndex = filterdselfcontrol.workordersData.findIndex(w => w.workOrder === selectedWorkorderData._id)
    if (workorderPointIndex !== -1) {
      const workorderPoint = filterdselfcontrol.workordersData[workorderPointIndex]
      filterdselfcontrol.workordersData[workorderPointIndex] = failed ?
        {
          ...workorderPoint,
          isFailed: failed,
          time: new Date(),
          isApproved: false,
          time: null,
          approvedBy: "",
          failedBy: currentUser,
          workOrder: selectedWorkorderData._id
        } : {
          ...workorderPoint,
          isFailed: failed,
          time: null,
          failedBy: "",
          workOrder: selectedWorkorderData._id
        }
    } else {
      filterdselfcontrol.workordersData.push({
        isFailed: failed,
        time: new Date(),
        failedBy: currentUser,
        workOrder: selectedWorkorderData._id
      })
    }
    allPoints[filterdselfcontrolIndex] = filterdselfcontrol;
    // allPoints[filterdselfcontrolIndex] = {
    //   ...filterdselfcontrol,
    //   isFailed: failed,
    //   time: new Date(),
    //   failedBy: currentUser,
    // };

    const payload = {
      ...selectedSelfControlsList,
      points: allPoints,
    };
    const updatedSelfControlList = await updateSelfControlLists(
      selectedSelfControlsList?._id,
      payload
    );
    if (updatedSelfControlList?.status) {
      setSelectedSelfControlsList(updatedSelfControlList?.data);
    }
  };

  const handleAddControlListInWorkorder = async () => {
    if (!selectedSelfControlsList) {
      return toast.error("Please select self-control list")
    }
    if (!selectedWorkorderData?.selfControlList) {
      const payload = { selfControlList: selectedSelfControlsList?._id };
      const data = await updateWorkorder(selectedWorkorderData?._id, payload);
      if (data.status) {
        toast.success("Selfcontrol list added successfully");
        dispatch(selectedWorkorder(data?.data));
      }
    } else {
      toast.info("To change the self-control list, please delete the current selection first.")
    }
  };

  const handleAttachment = async (file, pointId) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const upload = await fileUpload_Generic(formData);
      console.log("UPLOAD => ", upload);
      //console.log("SELECTDE WORKORDER => ", selectedWorkorderData);
      console.log("SELECTED SELF CONTROL LIST => ", selectedSelfControlsList);
      const allPoints = [...selectedSelfControlsList.points];
      console.log("ALL POINTS => ", allPoints);
      console.log("REC POINT ID => ", pointId);
      const indexOfThisPoint = allPoints.findIndex(
        (point) => point._id === pointId
      );
      console.log("INDEX => ", indexOfThisPoint);
      if (indexOfThisPoint !== -1) {
        console.log("THIS POINT => ", allPoints[indexOfThisPoint]);
      }

      // const arrayOfAttachemnts = [...allPoints[indexOfThisPoint].attachments];
      // console.log("ARRAY OF ATTACHMENTS => ", arrayOfAttachemnts);
      const existingfile = allPoints[indexOfThisPoint].attachments?.find(
        (e) => e.workOrder === selectedWorkorderData._id
      );
      if (existingfile) {
        const indx = allPoints[indexOfThisPoint].attachments.findIndex(
          (e) => e.workOrder === selectedWorkorderData._id
        );
        allPoints[indexOfThisPoint].attachments[indx] = {
          workOrder: selectedWorkorderData._id,
          attachmentUrl: upload.data.url,
          attachmentName: upload.data.docName,
        };
      } else {
        allPoints[indexOfThisPoint].attachments.push({
          workOrder: selectedWorkorderData._id,
          attachmentUrl: upload.data.url,
          attachmentName: upload.data.docName,
        });
      }
      console.log("UPDATED POINTS => ", allPoints);
      const updatedSelfControlList = await updateSelfControlLists(
        selectedSelfControlsList?._id,
        { points: allPoints }
      );

      setSelectedSelfControlsList(updatedSelfControlList?.data);
      toast.success("File uploaded successfully");
      // console.log("updatedSelfControlList => ", updatedSelfControlList);
    } catch (error) {
      console.log("ERROR IN ATTACHMENT UPLOAD");
      toast.error(error.message);
    }
  };

  const handleDeleteFile = async () => {
    if (selectedWorkorderData?.selfControlList) {
      const payload = { selfControlList: null };
      const data = await updateWorkorder(selectedWorkorderData?._id, payload);
      await updateSelfControlLists(
        selectedSelfControlsList?._id,
        {
          ...selectedSelfControlsList,
          points: selectedSelfControlsList.points.map(point => ({
            ...point,
            workordersData: point.workordersData.filter(workorder => workorder.workOrder !== selectedWorkorderData._id)
          }))
        }
      );
      if (data.status) {
        toast.success("Selfcontrol list deleted successfully");
        dispatch(selectedWorkorder(data?.data));
        setSelectedSelfControlsList({})
      }
    } else {
      toast.error("There is no self-control list to delete")
    }
  }

  const getSelfControlList = async () => {
    const list = await selfControlLists();
    setSelfControls(list?.data);
    const selfControl = list?.data?.find((s) => s._id === selectedWorkorderData?.selfControlList);
    setSelectedSelfControlsList(selfControl);
  };

  useEffect(() => {
    getSelectSelfControl(selectedWorkorderData?.selfControlList);
    if (!selfControls.length) {
      getSelfControlList();
    }
  }, []);

  return (
    <>
      {/* <div className="table-responsive alternative-colors mb-5">
        <DataTable
          columns={[
            {
              name: <div className="table-content"></div>,
              selector: (row) => (
                <input
                  type="checkbox"
                  id="myCheckbox"
                  name="myCheckbox"
                  onClick={() => {
                    handleSelfControlList(row);
                  }}
                />
              ),
            },
            ...selfControlListColumns,
          ]}
          data={selfControls}
          responsive={true}
        />
      </div>
      <div className="table-responsive alternative-colors mb-5 mt-5">
        <DataTable
          columns={selfControlListPointsColumns}
          data={selectedSelfControlsList}
          responsive={true}
        />
      </div> */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex  align-items-end gap-2">
          <div>
            <Form.Group className="w-5">
              <Form.Label>{t("Choose a selfcontroll list")}</Form.Label>
              <select
                className="form-select"
                onChange={(e) => getSelectSelfControl(e.target.value)}
                value={selectedSelfControlsList?._id}
                style={{ width: "300px" }}
              >
                <option value={""} selected>
                  {t("select_price_list")}
                </option>
                {selfControls.map((p) => (
                  <option value={p._id}>{p.name}</option>
                ))}
              </select>
            </Form.Group>
          </div>
          <div className="work-order-bottom-btn">
            <Button className="simple-btn mx-auto" onClick={handleAddControlListInWorkorder}>
              {t("save")}
              <i className="fa fa-save m-2 fs-5"></i>
            </Button>
          </div>

        </div>
        <div className="d-flex gap-2">
          <div className="create-btn m-1 cursor-pointer" onClick={handleDeleteFile}>
            {" "}
            <i className="fa fa-trash fs-5"></i>Delete file
          </div>
          <div className="create-btn m-1 cursor-pointer" onClick={() => selectedWorkorderData?.selfControlList ? setShowPdfModal(!showPdfModal) : toast("No self-control list saved")}>
            <img className="" src={PDFImage} />
            Create PDF
          </div>
        </div>
      </div>
      <div className="table-responsive alternative-colors mb-5 mt-5">
        <DataTable
          columns={selfControlListPointsColumns}
          data={selectedSelfControlsList?.points}
          responsive={true}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      <div>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showPdfModal}
          onHide={() => setShowPdfModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("time_report_PDF")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pdf-btn">
              <PDFViewer className="pdf-viewer">
                <SelfControlPdf selfControlList={selectedSelfControlsList?.points} selectedWorkorderData={selectedWorkorderData} />
              
              </PDFViewer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* <div className="w-100 p-3 work-order-bottom-btn flex justify-content-start gap-3 position-fixed bottom-0 bg-light">
        <div className="d-flex gap-3">
          <Button className="simple-btn mx-auto">
            {t("save")}
            <i className="fa fa-save m-2 fs-5"></i>
          </Button>
          <Button
            className="simple-btn mx-auto"
            onClick={() => {
              setIsPdfTypeModalVisible(true);
            }}
          >
            PDF View
          </Button>
          <Button
            className="simple-btn mx-auto"
            onClick={() => exportInvoice()}
          >
            {t("export_invoice")}
            <i className="fa fa-save m-2 fs-5"></i>
          </Button>
        </div>
      </div> */}

<div>
      <CommonButton
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
        exportInvoice={exportInvoice}
      />
    </div>

      <PdfTypeModal isPdfTypeModalVisible={isPdfTypeModalVisible} setIsPdfTypeModalVisible={setIsPdfTypeModalVisible} />
    </>
  );
};

export default SelfControlTab;
