import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const AddUpdateCutomerValidationSchema = (t) => {
  return Joi.object({
    name: Joi.string()
      .label(t("name"))
      .required()
      .messages(
        handleMassage(
          t("name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),

    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label(t("email"))
      .messages(
        handleMassage(
          t("email"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    phoneNumber: Joi.required()
      .label(t("phone_number"))
      .messages(
        handleMassage(
          t("phone_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    latePaymentInterest: Joi.number()
      .min(0)
      .max(100)
      .label("Late Payment Interest"),
    discount: Joi.number().min(0).max(100).label("Discount"),
    customerNumber0time: Joi.number().label("Customer Number (0 time)"),
    customerNumberFortnox: Joi.number().label("Customer Number (Fortnox)"),
    customerNumberVisma: Joi.number().label("Customer Number (Visma)"),
    customerNumberOther: Joi.number().label("Customer Number (Other)"),
    ORGNumber: Joi.number().label("ORG Number"),
    VATNumber: Joi.number().label("VAT number"),
    BRFNumber: Joi.number().label("BRF ORG number"),
  });
};

const SignUpValidationSchema = (t) => {
  return Joi.object({
    firstName: Joi.string()
      .required()
      .label(t("first_name"))
      .messages(
        handleMassage(
          t("first_name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    username: Joi.string()
      .required()
      .label(t("username"))
      .messages(
        handleMassage(
          t("username"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label(t("email"))
      .messages(
        handleMassage(
          t("email"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    phoneNumber: Joi.required()
      .label(t("phone_number"))
      .messages(
        handleMassage(
          t("phone_number"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    password: Joi.string()
      .required()
      .max(8)
      .min(6)
      .label(t("password"))
      .messages(
        handleMassage(
          t("password"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

const AddUpdateDeliveryAddressValidationScheme = (t) => {
  return Joi.object({
    addressDelivery: Joi.string()
      .label(t("address"))
      .required()
      .messages(
        handleMassage(
          t("address"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    zipCodeDelivery: Joi.string()
      .label(t("zip_code"))
      .required()
      .messages(
        handleMassage(
          t("zip_code"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    postortDelivery: Joi.string()
      .label(t("postort"))
      .required()
      .messages(
        handleMassage(
          t("postort"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

export {
  AddUpdateCutomerValidationSchema,
  SignUpValidationSchema,
  AddUpdateDeliveryAddressValidationScheme,
};
