import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createFacility,
  updateFacility,
} from "../../redux/actions/facilityActions";
import { customerList } from "../../redux/actions/customersActions";
import { useLocation, useNavigate } from "react-router-dom";
import { AddUpdateFacilityValidationSchema } from "./AddUpdateFacilityValidationSchema";
import { useTranslation } from "react-i18next";

const AddEditFacilityComponent = () => {
  const [customer, setCustomer] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [postalNo, setPostalNo] = useState("");
  const [city, setCity] = useState("");
  const [allCustomers, setAllCustomers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const formDataToBeValidated = {
      name,
      customer,
      address,
      postalNo,
      city,
    };
    const { error } = AddUpdateFacilityValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });

      return;
    }
    const payload = {
      name,
      address,
      postalNo,
      city,
      customer,
    };

    console.log("DATA SENT => ", payload);

    if (isEdit) {
      const data = await updateFacility(state?.facilityData?._id, payload);
      if (data.status) {
        toast.success(t("facility_updated_successfully"));
      }
    } else {
      const data = await createFacility(payload);
      if (data.status) {
        toast.success(t("facility_created_successfully"));
      }
    }
  };

  const getCustomersList = async () => {
    const { data } = await customerList();
    setAllCustomers(data || []);
  };

  useEffect(() => {
    getCustomersList();
  }, []);

  useEffect(() => {
    const data = state?.facilityData;
    if (data) {
      setIsEdit(true);
      setAddress(data.address || "");
      setCustomer(data.customer?._id || "");
      setName(data.name || "");
      setPostalNo(data.postalNo);
      setCity(data.city);
    }
  }, [state]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>Facility</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="row">
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Postal no</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={postalNo}
                        onChange={(e) => setPostalNo(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Customer</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setCustomer(e.target.value)}
                      value={customer}
                    >
                      <option value={""} selected>
                        Select customer
                      </option>
                      {allCustomers?.map((c) => (
                        <option value={c._id}>{c.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
              </div>
              <div className="mt-4">
                <Button className="simple-btn mx-auto" onClick={handleSubmit}>
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditFacilityComponent;
