import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { user } from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";

import Logo from "../assets/images/logo.png";

import { signup } from "../redux/actions/authActions";
import { SignUpValidationSchema } from "./customer/ValidationSchema";

function SignUp() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inputType, setInputType] = useState("password");
  const [companyName, setCompanyName] = useState("");
  const [organizationNumber, setOrganizationNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("")
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { t } = useTranslation();
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const formDataToBeValidated = {
      firstName,
      email,
      phoneNumber,
      password,
      username
    };
    const { error } = SignUpValidationSchema(t).validate(formDataToBeValidated);

    if (error) {
      console.log(error);
      error.details.forEach((err) => {
        toast.error(err.message);
        console.log(err);
      });

      return;
    }
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      companyName,
      organizationNumber,
      phone: phoneNumber,
      password,
    };
    try {
      const data = await signup(payload);
      if (data?.status) {
        toast.success(t("user_created_successfully"));
        localStorage.setItem("otid_user_token", data?.data.token);
        localStorage.setItem("otid_user", JSON.stringify(data?.data.user));
        dispatch(user(data?.data.user));
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("ERROR => ", error);
    }
  };

  return (
    <div className="loginMain">
      <div className="row mx-0 align-items-center">
        <div className="col-lg-6 loginImage">
          <div className="logo">
            <img src={Logo} alt="logo" />
            <h4>Lorem ipsum dolor sit amet consectetur. </h4>
          </div>
        </div>
        <div className="col-lg-6 my-auto">
          <div className="row mx-auto">
            <div className="col-md-12 col-lg-10 mx-auto">
              <div className="loginBox">
                <h3>Sign Up</h3>
                <div className="row px-2">
                  <div className="col-md-12 mx-auto">
                    <Form className="mt-3">
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>First Name</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Enter your first name..."
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Enter your last name..."
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Username</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Enter your email..."
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Enter your email..."
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Company Name</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Enter company name..."
                                onChange={(e) => setCompanyName(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Organization Number</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Enter organization number..."
                                onChange={(e) =>
                                  setOrganizationNumber(e.target.value)
                                }
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <InputGroup className="mb-3">
                              <PhoneInput
                                country={"us"}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                enableSearch={true}
                                dropdownStyle={{
                                  color: "black",
                                }}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>{" "}
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                type={inputType}
                                placeholder="Enter your password..."
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              {/* <InputGroup.Text
                            id="basic-addon1"
                            onClick={() =>
                              inputType === "password"
                                ? setInputType("text")
                                : setInputType("password")
                            }
                          >
                            <img
                              src="/imgs/u_eye.svg"
                              className="cursor-pointer"
                              alt=""
                            />
                          </InputGroup.Text> */}
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>

                      <div>
                        <Button
                          type="submit"
                          className="simple-btn w-100"
                          onClick={handleSignup}
                        >
                          Sign Up
                        </Button>
                      </div>
                      <div className="d-flex my-3 justify-content-center">
                        <p
                          className="forgotpassword"
                          onClick={() => {
                            // navigate('/forget-password')
                          }}
                        >
                          Already have account?
                        </p>
                        <p
                          className="mx-2 cursor-pointer text-primary"
                          onClick={() => navigate("/")}
                        >
                          Login
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
