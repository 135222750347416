import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Form, InputGroup } from "react-bootstrap";

import { AddArticleValidationSchema } from "../AddUpdateWorkorderValidationSchema";

const FreeTextArticlesTab = ({ workorder, sendDataToMaterial, wholesale }) => {
  const [itemNumber, setItemNumber] = useState();
  const [articleName, setArticelName] = useState("");
  const [quantity, setQuantity] = useState();
  const [unit, setUnit] = useState();
  const [entryPrice, setEntryPrice] = useState();
  const [priceOut, setPriceOut] = useState();

  const { t } = useTranslation();

  const handleArticleSubmit = async () => {
    const formDataToBeValidated = {
      itemNumber,
      articleName,
      quantity,
      unit
    };
    const { error } = AddArticleValidationSchema(t).validate(
      formDataToBeValidated
    );
    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });
      return;
    }
    const payload = {
      itemNumber,
      name: articleName,
      quantity,
      unit,
      entryPrice,
      priceOut,
      workOrderId: workorder._id,
      ArticleType: "freeText",
    };
    sendDataToMaterial(payload);
    setItemNumber("");
    setArticelName("");
    setQuantity("");
    setUnit("");
    setEntryPrice("");
    setPriceOut("");
    // const data = await createArticle(payload);
    // if (data.status) {
    //   getTextfreeArticles();
    //   toast.success(t("article_created_successfully"));
    // }
  };

  return (
    <>
      {/* Item Number */}
      <div className="col-xl-auto col-lg-4">
        <Form.Group className="mb-3">
          <Form.Label>{t("article_number")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={itemNumber}
              onChange={(e) => setItemNumber(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      {/* Name */}
      <div className="col-xl-auto col-lg-4">
        <Form.Group className="mb-3">
          <Form.Label>{t("article_name")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={articleName}
              onChange={(e) => setArticelName(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      {/* Quantity */}
      <div className="col-xl-auto col-lg-4">
        <Form.Group className="mb-3">
          <Form.Label>{t("quantity")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      {/* Unit */}
      <div className="col-xl-auto col-lg-4">
        <Form.Group className="mb-3">
          <Form.Label>{t("unit")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      {/* Entry Price */}
      {wholesale !== 'Underestreprened' &&
        <>
          <div className="col-xl-auto col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("purchase_price")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={entryPrice}
                  onChange={(e) => setEntryPrice(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {/* Price Out */}
          <div className="col-xl-auto col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("selling_price")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={priceOut}
                  onChange={(e) => setPriceOut(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </>
      }
      <div className="my-4">
        <Button className="simple-btn mx-auto" onClick={handleArticleSubmit}>
          {t("add_material")}
        </Button>
      </div>
    </>
  );
};

export default FreeTextArticlesTab;
