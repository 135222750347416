import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createProject = async (data) => {
  try {
    const res = await request.post(Constants.create_project_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const projectList = async () => {
  try {
    const res = await request.get(Constants.project_list_api_utl);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateProject = async (_id, project) => {
  try {
    const res = await request.post(Constants.update_project_api_url, {
      _id,
      project,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};