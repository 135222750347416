import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import SignUp from "../components/SignUpComponent";
import SignIn from "../components/SignInComponent";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import { PanelLayout } from "../layout/PanelLayout";
import UserManagement from "../pages/user-management/UserManagement";
import AddEditCustomerComponent from "../components/customer/AddEditCustomerComponent";
import CustomerListing from "../components/customer/CustomerListing";
import StaffListing from "../components/staff/StaffListing";
import AddEditStaffComponent from "../components/staff/AddEditStaffComponent";
import AddEditProject from "../components/project/AddEditProjectComponent";
import ProjectsListing from "../components/project/projectsListing";
import AddEditFacilityComponent from "../components/facility/AddEditFacilityComponent";
import FacilityListComponent from "../components/facility/FacilityListingComponent";
import AddEditWorkOrders from "../components/workorders/AddEditWorkOrder";
import WorkorderListComponent from "../components/workorders/WorkorderListComponent";
import FixedArticleListing from "../components/fixedArticles/FixedArticleListing";
import AddEditFixedArticle from "../components/fixedArticles/AddEditFixedArticle";
import AddEditResourceComponent from "../components/resources/AddEditResourceComponent";
import ResourcesListComponent from "../components/resources/ResourcesListComponent";
import AddEditSelfControlComponent from "../components/selfControl/AddEditSelfControlComponent";
import AddEditSelfControlListComponent from "../components/selfControl/selfControlList/AddEditSelfControlListComponent";
import SelfControlListing from "../components/selfControl/selfControlList/SelfControlListComponent";
import AddEditMachineComponent from "../components/machine/AddEditMachineComponent";
import MachinesListComponent from "../components/machine/MachineListComponent";
import AddEditStationsortComponent from "../components/stationsort/AddEditStationsortComponent";
import StationsortListing from "../components/stationsort/StationsortListComponent";
import { useSelector } from "react-redux";
import AddAdmin from "../components/CreateAdmin/AddAdmin";
import AdminList from "../components/CreateAdmin/AdminList";
import SelfControls from "../components/selfControl/SelfControlsComponent";
import AddEditPriority from "../components/priority/AddEditPriority";
import PriorityList from "../components/priority/PriorityListComponent";
import AddEditOrganizationComponent from "../components/organization/AddEditOrganizationComponent";
import OrganizationList from "../components/organization/OrganizationListComponent";
import AddEditFavoriteListComponent from "../components/favoriteList/AddEditFavoriteListComponent";
import FavoriteLists from "../components/favoriteList/FavoriteListsComponent";
import Fortnox from "../components/Settings/Integrationer";
import FortnoxIntegration from "../components/Settings/integrationer/FortnoxIntegration";
import TimereportOverviewComponent from "../components/Overviews/TimereportOverviewComponent";
import AddEditWorkroleComponent from "../components/workrole/AddEditWorkroleComponent";
import WorkroleListComponent from "../components/workrole/WorkroleListComponent";
import WorkScheduleComponent from "../components/workSchedule/WorkScheduleComponent";
import GrossisterTab from "../components/Settings/integrationer/GrossisterTab";
import DeviationOverviewComponent from "../components/Overviews/DeviationOverviewComponent";
import EDeliveryNotesComponent from "../components/EDeliveryComponent";

const PrivateRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const Index = () => {
  // Defining The Routes Of Whole Project.
  const userData = useSelector((state) => state.userData?.value);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/dashboard"
          errorElement={<ErrorPage />}
          element={
            <PrivateRoute isAuthenticated={userData}>
              <PanelLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<WorkorderListComponent />} />
          <Route path="users-management" element={<UserManagement />} />
          <Route path="add-customer" element={<AddEditCustomerComponent />} />
          <Route path="customer-listing" element={<CustomerListing />} />
          <Route path="add-staff" element={<AddEditStaffComponent />} />
          <Route path="staff-listing" element={<StaffListing />} />
          <Route path="add-project" element={<AddEditProject />} />
          <Route path="project-listing" element={<ProjectsListing />} />
          <Route path="add-facility" element={<AddEditFacilityComponent />} />
          <Route path="facilities-list" element={<FacilityListComponent />} />
          <Route path="add-work-order" element={<AddEditWorkOrders />} />
          <Route path="add-resource" element={<AddEditResourceComponent />} />
          <Route path="resources-list" element={<ResourcesListComponent />} />
          <Route
            path="add-self-control"
            element={<AddEditSelfControlComponent />}
          />
          <Route path="self-controls" element={<SelfControls />} />
          <Route
            path="add-self-control-list"
            element={<AddEditSelfControlListComponent />}
          />
          <Route path="self-control-list" element={<SelfControlListing />} />
          <Route path="add-machine" element={<AddEditMachineComponent />} />
          <Route path="machines-list" element={<MachinesListComponent />} />
          <Route
            path="add-stationsort"
            element={<AddEditStationsortComponent />}
          />
          <Route path="stationsort-list" element={<StationsortListing />} />
          <Route
            path="work-order-list"
            element={<WorkorderListComponent />}
          ></Route>
          <Route
            path="fixed-article-list"
            element={<FixedArticleListing />}
          ></Route>
          <Route
            path="add-fixed-article"
            element={<AddEditFixedArticle />}
          ></Route>
          <Route path="admins-list" element={<AdminList />}></Route>
          <Route path="add-admin" element={<AddAdmin />}></Route>
          <Route path="add-priority" element={<AddEditPriority />}></Route>
          <Route path="priority-list" element={<PriorityList />}></Route>
          <Route path="add-organization" element={<AddEditOrganizationComponent />}></Route>
          <Route path="organization-list" element={<OrganizationList />}></Route>
          <Route path="add-favorite-list" element={<AddEditFavoriteListComponent />}></Route>
          <Route path="favorite-lists" element={<FavoriteLists />}></Route>
          <Route path="fortnox" element={<Fortnox />}></Route>
          <Route path="grossistertab" element={<GrossisterTab />}></Route>
          <Route path="fortnoxintegration" element={<FortnoxIntegration />}></Route>
          <Route path="grossistertab" element={<GrossisterTab />} />
          <Route path="timereport-overview" element={<TimereportOverviewComponent />}></Route>
          <Route path="deviation-overview" element={<DeviationOverviewComponent />}></Route>
          <Route path="add-workrole" element={<AddEditWorkroleComponent />}></Route>
          <Route path="workrole-list" element={<WorkroleListComponent />}></Route>
          <Route path="work-schedule" element={<WorkScheduleComponent />}></Route>
          <Route path="e-delivery" element={<EDeliveryNotesComponent />}></Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default Index;
