import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./ProjectList.scss"
import { Link, useNavigate } from "react-router-dom";
import { projectList } from "../../redux/actions/projectActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function ProjectListing() {
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchColumns, setSearchColums] = useState([])
  const [selectedSearchCol, setSelactedSearchCol] = useState('name')
  const [projectTypeQuery, setProjectTypeQuery] = useState('')
  const [projectStatusQuery, setProjectStatusQuery] = useState('')
  const navigate = useNavigate();
  const { t } = useTranslation()

  const columns = [
    {
      name: <div className="table-content">{t('name')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-project", {
              state: { projectData: row },
            });
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t('type')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-project", {
              state: { projectData: row },
            });
          }}
        >
          {row.type}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t('customer')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-project", {
              state: { projectData: row },
            });
          }}
        >
          {row.customer?.name}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t('start_date')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-project", {
              state: { projectData: row },
            });
          }}
        >
          {new Date(row.startDate).toLocaleDateString()}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t('end_date')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-project", {
              state: { projectData: row },
            });
          }}
        >
          {new Date(row.endDate).toLocaleDateString()}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t('status')}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-project", {
              state: { projectData: row },
            });
          }}
        >
          {row.status}
        </div>
      ),
    },
  ];

  const getProjectList = async () => {
    const projectData = await projectList();
    if (projectData?.status) {
      setProjects(projectData?.data);
      setSearchColums([{ label: 'Name', value: 'name' }, { label: 'Project Number', value: 'projectNumber' }, { label: 'Customer', value: 'customer' }])
    }
  };

  useEffect(() => {
    if (projectTypeQuery && !searchQuery && !projectStatusQuery) {
      const data = projects.filter((p) => p.type === projectTypeQuery)
      setFilteredData(data)
    }
    if (projectStatusQuery && !searchQuery && !projectTypeQuery) {
      const data = projects.filter((p) => p.status === projectStatusQuery)
      setFilteredData(data)
    }
    if (searchQuery && !projectTypeQuery && !projectStatusQuery) {
      if (selectedSearchCol === 'customer') {
        const data = projects.filter((item) =>
          item[selectedSearchCol]?.name.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        setFilteredData(data)
      } else {
        const data = projects.filter((item) =>
          item[selectedSearchCol]?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        setFilteredData(data)
      }
    }

  }, [searchQuery, projectTypeQuery, projectStatusQuery])


  useEffect(() => {
    getProjectList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t('project')}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <div className="d-flex select-wrapper">
            <select className="form-control" onChange={(e) => (setProjectTypeQuery(e.target.value), setProjectStatusQuery(''), setSearchQuery(''))}>
              <option value=''>Project type</option>
              <option value='Main project'>Main project</option>
              <option value='Sub project'>Sub project</option>
            </select>
            <select className="form-control" onChange={(e) => (setProjectStatusQuery(e.target.value), setProjectTypeQuery(''), setSearchQuery(''))}>
              <option value=''>Status</option>
              <option value='Not started'>Not started</option>
              <option value='In progress'>In progress</option>
              <option value='Completed'>Completed</option>
            </select>
            <select
              className="form-control"
              onChange={(e) => (setSelactedSearchCol(e.target.value), setSearchQuery(''))}
            >
              {searchColumns?.map((p) => (
                <option value={p.value}>{p.label}</option>
              ))}
            </select >
          </div>
          <form action="" className="header-search">
            <input type="text" placeholder={t("search")} value={searchQuery} onChange={(event) => (setSearchQuery(event.target.value), setProjectStatusQuery(''), setProjectTypeQuery(''))} />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-project" className="simple-btn">
            {t('add_project')}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable columns={columns} data={(searchQuery || projectTypeQuery || projectStatusQuery) ? filteredData : projects} responsive={true} paginationPerPage={40} paginationRowsPerPageOptions={[10, 20, 30, 40]} />
      </div>
    </div>
  );
}
