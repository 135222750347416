import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const selfControlValidationSchema = (t) => {
  return Joi.object({
    points: Joi.string()
      .label(t("points"))
      .required()
      .messages(
        handleMassage(
          t("points"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    reference: Joi.string()
      .label(t("reference"))
      .messages(
        handleMassage(
          t("reference"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

const selfControlListValidationSchema = (t) => {
  return Joi.object({
    name: Joi.string()
      .label(t("name"))
      .required()
      .messages(
        handleMassage(
          t("name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    points: Joi.number()
      .label(t("points"))
      .required()
      .messages(
        handleMassage(
          t("points"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
  });
};

export { selfControlValidationSchema, selfControlListValidationSchema };
