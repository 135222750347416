import React from "react";
import loaderGif from "../assets/images/loading.gif";
import "./layout.scss";

export default function LoadingModal() {
    return (
        <div className="loader">
            <img src={loaderGif} height={70} width={70} alt="loaderGif" />
        </div>
    );
}
