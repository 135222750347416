import React from 'react'
import { useSelector } from "react-redux";

export const useIsExportInvoiceVisible = () => {
  const selectedWorkorderData = useSelector((state) => state.workorder.selectedWorkorder);
  return (
    selectedWorkorderData?.status.toLowerCase() !== "new offer" &&
    selectedWorkorderData?.status.toLowerCase() !== "approved offer" &&
    selectedWorkorderData?.status.toLowerCase() !== "denied offer"
  );
};