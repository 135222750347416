import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const signup = async (data) => {
  try {
    // User Signup Request
    const res = await request.post(Constants.signup_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const signIn = async (data) => {
  try {
    // User Signin Request
    const res = await request.post(Constants.signin_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const staffLogin = async (data) => {
  try {
    const res = await request.post(Constants.staff_signin_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const adminList = async () => {
  try {
    // Get Admin List Request
    const res = await request.get(Constants.admin_list_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateAdmin = async (_id, user) => {
  try {
    // Update Admin Record Request
    const res = await request.post(Constants.admin_update_api_url, {
      _id,
      user,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
