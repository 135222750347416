import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const AddUpdateProjectValidationSchema = (t) => {
  return Joi.object({
    type: Joi.string()
      .label(t("type"))
      .required()
      .messages(
        handleMassage(
          t("type"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    name: Joi.string()
      .label(t("name"))
      .required()
      .messages(
        handleMassage(
          t("name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    customer: Joi.string()
      .required()
      .label(t("customer"))
      .messages(
        handleMassage(
          t("customer"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),

    startDate: Joi.date()
      .required()
      .label(t("start_date"))
      .messages(
        handleMassage(
          t("start_date"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    endDate: Joi.date()
      .required()
      .label(t("end_date"))
      .messages(
        handleMassage(
          t("end_date"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    status: Joi.string()
      .required()
      .label(t("status"))
      .messages(
        handleMassage(
          t("status"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    // fortnox: Joi.string().required().label("What to send to fortnox").messages(handleMassage("What to send to fortnox")),
  });
};

export { AddUpdateProjectValidationSchema };
