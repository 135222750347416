import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createMachine,
  updateMachine,
} from "../../redux/actions/machineActions";
import { useLocation, useNavigate } from "react-router-dom";
import { machineValidationSchema } from "./MachineValidationSchema";
import { useTranslation } from "react-i18next";

const AddEditMachineComponent = () => {
  const [name, setName] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [internalNumber, setInternalNumber] = useState("");
  const [status, setStatus] = useState(true);
  const [information, setInformation] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const formDataToBeValidated = {
      name,
      regNumber,
      internalNumber,
    };
    const { error } = machineValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });

      return;
    }
    const payload = {
      name,
      backgroundColor,
      textColor,
      regNumber,
      internalNumber,
      information,
      isActive: status,
    };

    if (isEdit) {
      const data = await updateMachine(state?.machineData?._id, payload);
      if (data.status) {
        toast.success(t("machine_updated_successfully"));
        // navigate("/dashboard/machines-list");
      }
    } else {
      const data = await createMachine(payload);
      if (data.status) {
        toast.success(t("machine_created_successfully"));
        // navigate("/dashboard/machines-list");
      }
    }
  };

  useEffect(() => {
    const data = state?.machineData;
    if (data) {
      setIsEdit(true);
      setName(data.name);
      setBackgroundColor(data.backgroundColor || "");
      setTextColor(data.textColor || "");
      setRegNumber(data.regNumber);
      setInternalNumber(data.internalNumber || "");
      setStatus(data.isActive);
      setInformation(data.information || "");
    }
  }, [state]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("machine")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="row">
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("name")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("background_color")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="color"
                        value={backgroundColor}
                        onChange={(e) => setBackgroundColor(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("text_color")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="color"
                        value={textColor}
                        onChange={(e) => setTextColor(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("registration_number")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={regNumber}
                        onChange={(e) => setRegNumber(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("internal_number")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={internalNumber}
                        onChange={(e) => setInternalNumber(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("status")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option value={true} selected>
                        Active
                      </option>
                      <option value={false} selected>
                        Inactive
                      </option>
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("information")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={information}
                        onChange={(e) => setInformation(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="mt-4">
                <Button className="simple-btn mx-auto" onClick={handleSubmit}>
                  {isEdit ? t("update") : t("submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditMachineComponent;
