import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import workorderReducer from "../slices/workorderSlice";
import selfControlReducer from "../slices/selfControlListSlice";
import customerSlice from "../slices/customerSlice";
import { persistReducer, persistStore } from "redux-persist";
import timereportRrducer from "../slices/timereportSlice";
import fortnoxReducer from "../slices/fortnoxSlice"
import loadersReducer from "../slices/loadersSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userData: userReducer,
  workorder: workorderReducer,
  selfControl: selfControlReducer,
  timereport: timereportRrducer,
  customer: customerSlice,
  fortnox: fortnoxReducer,
  loaders: loadersReducer
});

//Creating The Redux Store And Apply Middleware.
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
