import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoiceData: null,
};

export const slice = createSlice({
  name: "timereportSlice",
  initialState,
  reducers: {
    invoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
  },
});

export const { invoiceData } = slice.actions;

export default slice.reducer;
