import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createResource,
  getRescurcesList,
  updateResource,
} from "../../redux/actions/resourceActions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEBIT_FORM,
  TIME_RECORDING,
  RESOURCE_TYPE,
} from "../../constants/Contants";
import { ResourceValidationSchema } from "./ResourceValidationSchema";
import { useTranslation } from "react-i18next";

const AddEditResourceComponent = () => {
  const [resourceType, setResourceType] = useState("dehumidifier");
  const [name, setName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [debitForm, setDebitForm] = useState("");
  const [timeRecording, setTimeRecording] = useState("");
  const [internalNumber, setInternalNumber] = useState("");
  const [kilowattHours, setKilowattHours] = useState("");
  const [stationsort, setStationsort] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const formDataToBeValidated = {
      name,
      resourceType,
      unitPrice,
      debitForm,
      // registrationNumber
    };
    const { error } = ResourceValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });

      return;
    }
    const payload = {
      resourceType,
      name,
      registrationNumber,
      unitPrice,
      debitForm,
      timeRecording,
      internalNumber,
      kilowattHours,
      stationsort,
    };

    console.log("DATA SENT => ", payload);

    if (isEdit) {
      const data = await updateResource(state?.resourceData?._id, payload);
      if (data.status) {
        toast.success(t("resource_updated_successfully"));
        // navigate("/dashboard/resources-list");
      }
    } else {
      const data = await createResource(payload);
      if (data.status) {
        toast.success(t("resource_created_successfully"));
        // navigate("/dashboard/resources-list");
      }
    }
  };
  console.log({ isEdit });
  useEffect(() => {
    const data = state?.resourceData;
    if (data) {
      setIsEdit(true);
      setResourceType(data.resourceType || "");
      setRegistrationNumber(data.registrationNumber || "");
      setName(data.name || "");
      setUnitPrice(data.unitPrice);
      setTimeRecording(data.timeRecording || "");
      setDebitForm(data.debitForm || "");
      setInternalNumber(data.internalNumber || "");
      setKilowattHours(data.kilowattHours || "");
      setStationsort(data.stationsort);
    }
  }, [state]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("resource")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="row">
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("type")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setResourceType(e.target.value)}
                      value={resourceType}
                    >
                      <option value={""} selected>
                        Select
                      </option>
                      {RESOURCE_TYPE?.map((c) => (
                        <option value={c.value}>{c.label}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("name")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("unit_price")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={unitPrice}
                        onChange={(e) => setUnitPrice(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("debit_form")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setDebitForm(e.target.value)}
                      value={debitForm}
                    >
                      <option value={""} selected>
                        Select
                      </option>
                      {DEBIT_FORM?.map((c) => (
                        <option value={c.value}>{c.label}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("time_recording")}</Form.Label>
                    <select
                      className="form-select mb-3"
                      onChange={(e) => setTimeRecording(e.target.value)}
                      value={timeRecording}
                    >
                      <option value={""} selected>
                        Select
                      </option>
                      {TIME_RECORDING?.map((c) => (
                        <option value={c.value}>{c.label}</option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                {resourceType === "dehumidifier" && (
                  <div className="col-lg-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("kilowatt_hours")}</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          value={kilowattHours}
                          onChange={(e) => setKilowattHours(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                )}
                {resourceType === "machine" && (
                  <>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("registration_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={registrationNumber}
                            onChange={(e) =>
                              setRegistrationNumber(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("stationsort")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={stationsort}
                            onChange={(e) => setStationsort(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("internal_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={internalNumber}
                            onChange={(e) => setInternalNumber(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </>
                )}
                {/* <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div> */}
              </div>
              <div className="mt-4">
                <Button className="simple-btn mx-auto" onClick={handleSubmit}>
                  {isEdit ? t("Update") : t("Submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditResourceComponent;
