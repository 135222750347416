import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading : false,
};

export const slice = createSlice({
  name: "loadersSlice",
  initialState,
  reducers: {
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { isLoading } = slice.actions;

export default slice.reducer;
