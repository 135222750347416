import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Logo from "../assets/images/logo.png";

import { signIn, staffLogin } from "../redux/actions/authActions";
import { user } from "../redux/slices/userSlice";

function SignIn() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [inputType, setInputType] = useState("password");
  const [staffNumber, setStaffNumber] = useState(0)
  const [loginAs, setLoginAs] = useState("")
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    const payload = {
      username,
      password,
      clientId: staffNumber,
      loginAs
    };
    let data;
    // if (staffNumber) {
    //   data = await staffLogin(payload)
    // } else {
    data = await signIn(payload);
    // }
    if (data?.status) {
      localStorage.setItem("otid_user_token", data?.data.token);
      localStorage.setItem("otid_user", JSON.stringify(data?.data.user));
      dispatch(user(data?.data.user));
      navigate("/dashboard");
    } else {
      toast.error(data?.message);
    }
  };

  return (
    <div className="loginMain">
      <div className="row mx-0">
        <div className="col-lg-6 col-md-6 loginImage">
          <div className="logo">
            <img src={Logo} alt="logo" />
            <h4>Lorem ipsum dolor sit amet consectetur. </h4>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 my-auto">
          <div className="row mx-0">
            <div className="col-md-12 col-lg-10 mx-auto">
              <div className="loginBox">
                <h3>Sign In</h3>
                <div className="row px-2">
                  <div className="col-md-12 mx-auto">
                    <Form className="mt-3">
                      <Form.Group className="mb-3">
                        <Form.Label>{"Log in As"}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setLoginAs(e.target.value)}
                          value={loginAs}
                        >
                          <option value={""} selected>
                            select
                          </option>
                          <option value={"otidadmin"}>
                            Administrator
                          </option>
                        </select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Client Number</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Enter customer number..."
                            onChange={(e) => setStaffNumber(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Enter your username..."
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            type={inputType}
                            placeholder="Enter your password..."
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <div>
                        <Button
                          type="submit"
                          className="simple-btn w-100"
                          onClick={handleSignIn}
                        >
                          Sign In
                        </Button>
                      </div>
                      <div className="d-flex my-3 justify-content-center">
                        <p
                          className="forgotpassword"
                          onClick={() => {
                            // navigate('/forget-password')
                          }}
                        >
                          Don't have an account?
                        </p>
                        <p
                          className="mx-2 cursor-pointer text-primary"
                          onClick={() => navigate("/signup")}
                        >
                          Sign Up
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
