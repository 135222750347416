import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { AddFixedArticleValidationSchema } from "./FixedArticleValidationSchema";
import {
  createFixedArticle,
  updateFixedArticle,
} from "../../redux/actions/fixedArticleActions";
import { useTranslation } from "react-i18next";

const AddEditFixedArticle = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActice] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const formDataToBeValidated = {
      name,
      description,
    };
    const { error } = AddFixedArticleValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      console.log(error);
      error.details.forEach((err) => {
        toast.error(err.message);
        console.log(err);
      });

      return;
    }
    const payload = {
      name,
      description,
      active,
    };
    if (isEdit) {
      console.log("UPDATE", payload);
      const data = await updateFixedArticle(
        state.fixedArticleData._id,
        payload
      );
      if (data.status) {
        toast.success(t("fixed_article_updated_successfully"));
        // navigate("/dashboard/fixed-article-list");
      }
    } else {
      console.log("CREATE");
      const data = await createFixedArticle(payload);
      if (data.status) {
        toast.success(t("fixed_article_created_successfully"));
        // navigate("/dashboard/fixed-article-list");
      }
    }
  };

  useEffect(() => {
    const data = state?.fixedArticleData;
    if (data) {
      setIsEdit(true);
      setName(data?.name || "");
      setDescription(data?.description || "");
      setActice(data?.active || false);
    }
  }, []);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("fixed_article")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="row">
                {/* Name */}
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("name")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                {/* Description */}
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("description")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                {/* Active */}
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("active")} <span>{/* <FaQuestionCircle /> */}</span>
                    </Form.Label>

                    <Form.Check
                      type="switch"
                      checked={active}
                      onChange={(e) => setActice(e.target.checked)}
                      style={{
                        transform: "scale(0.7)",
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="mt-4">
                <Button className="simple-btn mx-auto" onClick={handleSubmit}>
                  {isEdit ? t("update") : t("submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditFixedArticle;
