import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./layout.scss";
import { Sidebars } from "../pages/sidebar/Sidebars";
import panelLogo from "../assets/images/logo.png";
import LoadingModal from "./LoadingModal";
import { useSelector } from "react-redux";

export const PanelLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const {loaders} = useSelector(state => state)
  const sideBarMenu = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="panel-wrapper flex flex-wrap ">
        <div className={`panel-sidebar ${openSidebar ? "hide" : "show"}`}>
          <figure>
            <Link to="/">
              <img src={panelLogo} alt={panelLogo} />
            </Link>
          </figure>
          <Sidebars sideBarHandler={sideBarMenu} />
        </div>
        {/* </OutsideClickHandler> */}
        <div
          className={`panel-main-content flex column-direction ${openSidebar ? "hide" : "show"
            }`}
        >
          <header className="panel-header items-center flex content-justify-between">
            <div className="left-side">
              <button
                onClick={sideBarMenu}
                className={`side-menu-btn border-0 ${openSidebar ? "hide" : "show"
                  }`}
                style={{ fontSize: "25px" }}
              >
                {openSidebar === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 24 24"
                    style={{ fill: "rgba(0, 0, 0, 1)" }}
                  >
                    <path d="M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 24 24"
                    style={{ fill: "rgba(0, 0, 0, 1)" }}
                  >
                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                  </svg>
                )}
              </button>
            </div>
          </header>
          <div className="panel-main-wrapper">
          {loaders?.isLoading && <LoadingModal />}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
