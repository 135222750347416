import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  section: {
    flexDirection: "row",
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    alignSelf: "flex-start",
    marginTop: 25,
  },
  tableRow: {
    flexDirection: "row",
    fontWeight: "normal",
  },
  tableRowHead: {
    flexDirection: "row",
    textTransform: "uppercase",
    color: "#000",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    fontWeight: 900,
  },
  tableCol: {
    width: "33.33%",
    padding: 4,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  tableStripped: {
    backgroundColor: "#eeeeee",
  },
  largePage: {
    size: "A2",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  tableRowLast: {
    borderTopColor: "#000",
    borderTopWidth: 1,
    fontWeight: 900,
  },
  image: {
    width: 100,
    marginTop: 10,
  },
  imgCol: {
    width: "35%",
    textAlign: "center",
  },
  heading: {
    width: "65%",
    marginTop: 20,
  },
  headerSection: {
    flexDirection: "row",
    marginTop: 15,
  },
  headerCol: {
    width: "33%",
    textAlign: "left",
  }
});


const TimereportPdf = ({ timereports }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size="A2" style={styles.largePage}>
      <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <Text style={{ fontSize: 25, fontWeight: "bold", }}>20190427 elcentral inligt offert</Text>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Customer</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>20203 Stena Kobbe åäö</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739163396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Contact person</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>roswell niklasson</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Workplace address</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>Björkhemsvägen 26</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>29154 Kristianstad</Text> */}
              </View>
            </View>
          </View>
        </View>
        { }
        <View style={styles.table}>
          <View style={(styles.tableRowHead)}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("ao_name")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("date")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("start")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("stop")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("notes")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("working_hours")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>OB1</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>OB2</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Breaks</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ÖT1</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ÖT2</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Driving</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>KM</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("sum")}</Text>
            </View>
          </View>
          {timereports?.map((t) => (
            <View style={[styles.tableRow, styles.tableStripped]}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>ertyu</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{t.clockTime ? moment(t.date).format("DD-MM-YYYY") : ""}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.clockTime ? moment(t.start).format("HH:MM") : ""}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{moment(t.stop).format("HH:MM")}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.note}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.routineHours}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.overtime}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.hoursOnWeekend}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.totalHours}</Text>
              </View>
            </View>
          ))}
          {/* <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text>2024-03-26</Text>
                {"\n"}
                <Text>07:00</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text>2024-03-26</Text>
                {"\n"}
                <Text>07:00</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Micke Orrgren</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>8.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>2.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>10.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
          </View> */}
          {/* <View style={[styles.tableRow, styles.tableStripped]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text>2024-03-26</Text>
                {"\n"}
                <Text>07:00</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text>2024-03-26</Text>
                {"\n"}
                <Text>07:00</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Micke Orrgren</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>8.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>2.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>10.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
          </View> */}
          {/* <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text>2024-03-26</Text>
                {"\n"}
                <Text>07:00</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text>2024-03-26</Text>
                {"\n"}
                <Text>07:00</Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Micke Orrgren</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>8.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>2.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>10.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
          </View> */}
          {/* <View style={[styles.tableRow, styles.tableRowLast]}>
            <View style={styles.tableCol}>
            </View>
            <View style={styles.tableCol}>
            </View>
            <View style={styles.tableCol}>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>24.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>6.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>10.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>40.00</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>0.00</Text>
            </View>
          </View> */}
        </View>
      </Page>
    </Document>
  )
};

export default TimereportPdf;
