import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createResource = async (data) => {
  try {
    const res = await request.post(Constants.create_resource_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const getRescurcesList = async () => {
  try {
    const res = await request.get(Constants.resources_list_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateResource = async (_id, resource) => {
  try {
    const res = await request.post(Constants.update_resource_api_url, {
      _id,
      resource,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};