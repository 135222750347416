import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const ResourceValidationSchema = (t) => {
  return Joi.object({
    name: Joi.string()
      .label(t("name"))
      .required()
      .messages(
        handleMassage(
          t("name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    resourceType: Joi.string()
      .required()
      .label(t("type"))
      .messages(
        handleMassage(
          t("type"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    unitPrice: Joi.number()
      .label(t("unit_price"))
      .required()
      .messages(
        handleMassage(
          t("unit_price"),
          t("is_a_required"),
          t("should_be_a_type_of_'number'")
        )
      ),
    debitForm: Joi.string()
      .label(t("debit_form"))
      .required()
      .messages(
        handleMassage(
          t("debit_form"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

export { ResourceValidationSchema };
