import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import CommonButton from "./CommonButton";
import { selectedWorkorder } from "../../../redux/slices/workorderSlice";
import {
  updateWorkorder,
  fileUpload,
  deleteWorkorderAttributes,
  updateWorkorderAttributes,
} from "../../../redux/actions/workorderActions";
import moment from "moment";
import PDFImage from "../../../assets/images/download.png";
import Modal from "react-bootstrap/Modal";
import DocumentPdf from "../PDFs/DocumentPdf";
import { PDFViewer } from "@react-pdf/renderer";
import PdfTypeModal from "../PDFs/PdfsTypeModal";
import axios from "axios";

const DocNameColumnsRow = (props) => {
  let { row, documents, setDocument } = props;

  const [edit, setEdit] = useState(false);
  const [updatedName, setUpdatedName] = useState(row.docName);

  const { t } = useTranslation();

  const handleTableAlreadyExistingMAterial = () => {
    const existingDocIndex = documents.findIndex((m) => m._id === row._id);
    if (existingDocIndex !== -1) {
      setDocument(updatedName, existingDocIndex);
      setEdit(false);
    }
  };

  return (
    <>
      <div className="d-flex gap-2 justify-content-start align-items-center">
        {edit ? (
          <>
            <form className="d-flex w-100">
              <input
                className="w-50"
                value={updatedName}
                onChange={(e) => {
                  setUpdatedName(e.target.value);
                }}
              />
              <button
                className="simple-btn table-edit-btn w-5 p-1 rounded-0"
                type="submit"
                onClick={handleTableAlreadyExistingMAterial}
              >
                {t("ok")}
              </button>
            </form>
          </>
        ) : (
          <>
            <span>{row.docName}</span>
            <i
              className="fa fa-edit ms-auto me-4"
              onClick={() => {
                setEdit(true);
              }}
            ></i>
          </>
        )}
      </div>
    </>
  );
};

const DocumentTab = ({ exportInvoice }) => {
  const [uploadedDocuments, setUploadedDocument] = useState([]);
  const [uploadedDoc, setUploadedDoc] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);

  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userData?.value);
  const currentUser = `${user?.first_name} ${user.last_name}`;
  const selectedWorkorderData = useSelector(
    (state) => state.workorder.selectedWorkorder
  );

  const documentColumns = [
    {
      name: <div className="table-content">{t("document")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">
          {/* {row.docName} */}
          <DocNameColumnsRow
            row={row}
            documents={uploadedDocuments}
            setDocument={handleUpdateDocument}
          />
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("uploaded_by")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">{row.uploadedBy}</div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("date")}</div>,
      selector: (row) => (
        <div className="jobPosition cursor-pointer">
          {moment(row.date).format("YYYY-MM-DD")}
        </div>
      ),
      wrap: true,
    },
  ];

  const handleDocumentList = (data) => {
    setUploadedDocument(data?.data.document);
    dispatch(selectedWorkorder(data?.data));
  };

  const handleRemoveDocument = async (data) => {
    const row = data;
    const userConfirmation = window.confirm(
      t("are_you_sure_you_want_to_delete_this_document?")
    );
    if (userConfirmation) {
      const doc = uploadedDocuments.filter((u) => u._id === row._id);
      const data = await deleteWorkorderAttributes(
        state?.workorderData?._id,
        doc[0],
        "document"
      );
      if (data.status) {
        handleDocumentList(data);
        toast.success(t("document_deleted_successfully"));
      }
    }
  };

  const handleUpdateDocument = async (name, index) => {
    let copy = { ...uploadedDocuments[index] };
    copy.docName = name;
    let arrCopy = [...uploadedDocuments];
    arrCopy[index] = { ...copy };
    setUploadedDocument([...arrCopy]);
    const data = await updateWorkorderAttributes(
      state?.workorderData?._id,
      copy,
      "document"
    );  
    if (data.status) {
      handleDocumentList(data);
      toast.success(t("document_updated_successfully"));
    }
  };
  // FILE UPLOAD HERE
  const handleFileUpload = async () => {
    if (!uploadedDoc.length) {
      return toast.error(t("no_file_found"));
    }
    const uploadPromises = uploadedDoc?.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      const upload = await fileUpload(
        formData,
        state?.workorderData?._id,
        currentUser
      );
      return upload;
    });
  
    try {
      const uploadResults = await Promise.all(uploadPromises);
      const allUploadsSuccessful = uploadResults.every((upload) => upload?.status);
  
      if (allUploadsSuccessful) {
        toast.success(t("document_successfully_uploaded"));
        uploadResults.forEach((upload) => handleDocumentList(upload));
      } else {
        toast.error(t("some documents failed to upload"));
      }
  
      setUploadedDoc([]);
    } catch (error) {
      toast.error(t("error uploading documents"));
    }
  };

  async function getS3ImageBase64(url) {
    try {
      const response = await axios.get(url, { responseType: 'blob' }); // Set responseType to 'blob'
      console.log("====response", response);
      const blob = response.data; // Access the response body directly
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching and converting image:', error);
      throw error;
    }
  }

  const handleDocumentsPdfData = async (documents) => {
    let updatedData = []; // Initialize the array
    for (let document of documents) {
      let base64Data = await getS3ImageBase64(document.url); // Wait for getS3ImageBase64 to resolve
      let d = { ...document, url: base64Data }
      updatedData.push(d);
    }
    return updatedData; // Return the updatedData array
  }

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setUploadedDoc((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDelete = (index) => {
    setUploadedDoc((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const data = selectedWorkorderData;
    if (data) {
      setUploadedDocument(data.document);
    }
  }, []);

  return (
    <>
      <Form className="form-design">
        <div className="row gx-5 py-3 mb-3 align-items-center">

          <div className="col-lg-3">
            <Form.Group>
              <InputGroup>
                <Form.Control
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  multiple
                  onChange={handleFileChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Button className="simple-btn mx-auto" onClick={handleFileUpload}>
              {t("upload")}
              <i className="fa fa-save m-2 fs-5"></i>
            </Button>
          </div>
          <div className="col-lg-6 justify-content-end d-flex">
            <div className="create-btn m-1 cursor-pointer"
              onClick={() => uploadedDocuments.length ? setShowPdfModal(!showPdfModal) : toast("There is no document for PDF")}
            >
              <img className="" src={PDFImage} />
              Create PDF
            </div>
          </div>
        </div>
        <div className="d-flex flex-direction-column">
          {uploadedDoc?.map((file, index) => (
            <div key={index}>{
              file.name}
              <Button className="m-2" variant="danger" size="sm" onClick={() => handleDelete(index)}> <i className="fa fa-trash fs-5"></i></Button>
            </div>
          ))}
        </div>
      </Form>
      <div className="table-responsive alternative-colors mb-5">
        <DataTable
          columns={[
            ...documentColumns,
            {
              name: <div className="table-content"></div>,
              selector: (row) => (
                <div className="jobPosition cursor-pointer">
                  <div className="work-order-bottom-btn bg-transparent">
                    <Button
                      className="simple-btn"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        window.open(row.url, "_blank");
                      }}
                    >
                      <i className="fa fa-eye m-2 fs-5"></i>
                    </Button>
                    <Button
                      className="simple-btn"
                      onClick={() => handleRemoveDocument(row)}
                    >
                      <i className="fa fa-trash m-2 fs-5"></i>
                    </Button>
                  </div>
                </div>
              ),
              wrap: true,
            },
          ]}
          data={uploadedDocuments}
          responsive={true}
        />
      </div>
      <div>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showPdfModal}
          onHide={() => setShowPdfModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Document PDF")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pdf-btn">
              <PDFViewer className="pdf-viewer">
                <DocumentPdf documents={uploadedDocuments} />
              </PDFViewer>
            </div>
          </Modal.Body>
        </Modal>
      </div>
  

<div>
      <CommonButton
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
        exportInvoice={exportInvoice}
      />
    </div>

      <PdfTypeModal isPdfTypeModalVisible={isPdfTypeModalVisible} setIsPdfTypeModalVisible={setIsPdfTypeModalVisible} />
    </>
  );
};

export default DocumentTab;
