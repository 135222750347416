import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createInvoice = async (data) => {
  try {
    const res = await request.post(Constants.create_invoice_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const invoiceList = async (workorderId) => {
  try {
    const res = await request.get(`${Constants.invoice_list_api_url}/${workorderId}`);
    console.log("ok" + res)

    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateInvoice = async (_id, invoice, workorderId, materialId) => {
  try {
    const res = await request.post(Constants.update_invoice_api_url, {
      _id,
      invoice,
      workorderId,
      materialId
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};


// export const updateInvoice = (_id, invoice, workorderId, materialId) => {
//   return {
//     type: 'UPDATE_INVOICE',
//     payload: {
//       _id,
//       invoice,
//       workorderId,
//       materialId
//     }
//   };
// };

export const deleteInvoice = async (_id, invoice) => {
  try {
    const res = await request.post(`${Constants.delete_invoice_api_url}/${_id}`, {
      invoice,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const createFortnoxArticle = async (data) => {
  try {
    const res = await request.post(`/fortnox/create-article`, data);
    if (res?.data?.status) {
     // toast.success(res.data.message)
    } else {
      if(res?.data?.message?.ErrorInformation?.code !== 2000013)
      toast.error("There is something wrong while creating article fortnox.");
    }
  } catch (e) {
    toast.error(e.message);
  }
};