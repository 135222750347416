import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createFixedArticle = async (data) => {
  try {
    const res = await request.post(Constants.create_fixedArticle_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateFixedArticle = async (_id, user) => {
  try {
    const res = await request.post(Constants.update_FixedArticle_api_url, {
      _id,
      user,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const fixedArticleList = async () => {
  try {
    const res = await request.get(Constants.fixedArticle_list_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
