import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import FreeTextArticlesTab from "./FreeTextArticlesTab";
import { WHOLESALE_LIST } from "../../../constants/Contants";
import { AddMaterialValidationSchema } from "../AddUpdateWorkorderValidationSchema";
import OutsideClickHandler from "react-outside-click-handler";
import CommonButton from "./CommonButton";
import {
  getPriceList,
  updateWorkorder,
  createWorkorderAttributes,
  deleteWorkorderAttributes,
  updateWorkorderAttributes,
} from "../../../redux/actions/workorderActions";
import { getFavoriteLists, addFavoriteMaterials } from "../../../redux/actions/favoriteListActions";
import { selectedWorkorder } from "../../../redux/slices/workorderSlice";
import { invoiceData } from "../../../redux/slices/timereportSlice";
import ArticleMegaMenu from "./ArticleMegaMenu";
import PdfTypeModal from "../PDFs/PdfsTypeModal";


const MaterialQuantityColumnsRow = (props) => {
  let { row, materialList, setMaterialList } = props;
  const [editQuantity, setEditQuantity] = useState(false);
  const [editQuantityValue, setEditQuantityValue] = useState(row.quantity);
  const { t } = useTranslation();

  const handleTableAlreadyExistingMaterial = () => {
    const existingMaterialIndex = materialList.findIndex(
      (m) => m.wholesale === row.wholesale && m.number === row.number
    );
    if (existingMaterialIndex !== -1) {
      let qtyEdit = true;
      setMaterialList(editQuantityValue, existingMaterialIndex, false);
      setEditQuantity(false);
    }
  };

  return (
    <div className="d-flex gap-2 justify-content-start align-items-center">
      {editQuantity ? (
        <form
          className="d-flex w-120"
          onSubmit={(e) => {
            e.preventDefault();
            handleTableAlreadyExistingMaterial();
          }}
        >
          <input
            className="w-50"
            value={editQuantityValue}
            onChange={(e) => {
              setEditQuantityValue(e.target.value);
            }}
          />
          <button
            className="simple-btn table-edit-btn w-50 p-1 rounded-0"
            type="submit"
          >
            {t("ok")}
          </button>
        </form>
      ) : (
        <>
          <span>{(row.quantity)}</span>
          <i
            className="fa fa-edit ms-auto me-4"
            onClick={() => {
              setEditQuantity(true);
            }}
          ></i>
        </>
      )}
    </div>
  );
};

const MaterialPriceColumnsRow = (props) => {
  let { row, materialList, setMaterialList, formateNumber } = props;
  const [editPrice, setEditPrice] = useState(false);
  const [editPriceValue, setEditPriceValue] = useState(row.sellingPrice);
  const { t } = useTranslation();

  const handleTableAlreadyExistingMaterial = () => {
    const existingMaterialIndex = materialList.findIndex(
      (m) => m.wholesale === row.wholesale && m.sellingPrice === row.sellingPrice
    );
    if (existingMaterialIndex !== -1) {
      let priceEdit = true;
      setMaterialList(editPriceValue, existingMaterialIndex, true);

      setEditPrice(false);
    }
  };

  return (
    <div className="d-flex gap-2 justify-content-start align-items-center">
      {editPrice ? (
        <form
          className="d-flex w-100"
          onSubmit={(e) => {
            e.preventDefault();
            handleTableAlreadyExistingMaterial();
          }}
        >
          <input
            className="w-50"
            value={editPriceValue}
            onChange={(e) => {
              setEditPriceValue(e.target.value);
            }}
          />
          <button
            className="simple-btn table-edit-btn w-50 p-1 rounded-0"
            type="submit"
          >
            {t("ok")}
          </button>
        </form>
      ) : (
        <>
          <span>{formateNumber(row.sellingPrice)}</span>
          <i
            className="fa fa-edit ms-auto me-4"
            onClick={() => {
              setEditPrice(true);
            }}
          ></i>
        </>
      )}
    </div>
  );
};


const MaterialTab = ({ exportInvoice, addMaterialFromInvoice, invoiceId, addMaterialFromFavorites, isOffer }) => {
  /// Material ///
  const [materialWholesale, setMaterialWholesale] = useState("wholeseller");
  const [materialName, setMaterialName] = useState("");
  const [materialItemNumber, setMaterialItemNumber] = useState("");
  const [materialQty, setMaterialQty] = useState("");
  const [materialUnit, setMaterialUnit] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [materialAward, setMaterialAward] = useState("");
  const [materailVAT, setmaterailVAT] = useState("");
  const [ueInvoiceNo, setUeInvoiceNo] = useState("");
  const [ueMaterialCost, setUeMaterialCost] = useState("");
  const [ueLaborCost, setUeLaborCost] = useState("");
  const [ueSurcharge, setUeSurcharge] = useState("");
  const [priceListDataState, setPriceListDataState] = useState([]);
  const [matchPriceObjState, setMatchPriceObjState] = useState([]);
  const [favoritsLists, setFavoriteLists] = useState([])
  const [checkedFavLists, setCheckedFavLists] = useState([]);
  const [favMaterials, setFavMaterials] = useState([]);
  const [isActive, setActive] = useState(false);
  const [articleNameBox, setArticleNamebox] = useState(false)
  const [favoriteWholesaleWindow, setFavoriteWholesaleWindow] = useState(false)
  const [articleNumberSearch, setArticleNumberSearch] = useState("")
  const [articleNameSearch, setArticleNameSearch] = useState("")
  const [filteredMaterial, setFilteredMaterial] = useState([])
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);
  const [favMaterialFromFavList, setFavMaterialFromFavList] = useState([])

  const handleToggle = () => {
    setActive(!isActive);
  };

  const handleNameBoxToggle = () => {
    setArticleNamebox(!articleNameBox);
  };

  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData.value);
  const selectedWorkorderData = useSelector(
    (state) => state.workorder.selectedWorkorder
  );


  const reset = () => {
    setMaterialName("");
    setMaterialUnit("");
    setPurchasePrice("");
    setMaterialQty("");
    setMaterialItemNumber("");
    setSellingPrice("");
  };

  const filterObjectsByNumber = (arr, targetNumber) => {
    const filteredObjects = arr.filter((obj) => {
      for (const key in obj) {
        if (typeof obj[key] === "string" && obj[key] === targetNumber) {
          return true;
        }
      }
      return false;
    });
    return filteredObjects;
  };

  const getPriceListApiCallFunc = async () => {
    const dataPriceList = await getPriceList();
    if (dataPriceList?.status) {
      setPriceListDataState(dataPriceList?.data);
    }
  };

  useEffect(() => {
    getPriceListApiCallFunc();
  }, []);

  useEffect(() => {
    
    const data = favoritsLists?.find(f => f._id === materialWholesale)
    
    if (data) {
      setFavMaterialFromFavList(data.favorites)
    } else {
      setFavMaterialFromFavList(priceListDataState);
    }

  }, [materialWholesale])

  useEffect(() => {
    if (matchPriceObjState[0]) {
      setMaterialName({ label: matchPriceObjState[0]?.name, value: matchPriceObjState[0]?.name });
      setMaterialItemNumber({ label: matchPriceObjState[0].number, value: matchPriceObjState[0]?.number })
      setMaterialUnit(matchPriceObjState[0]?.unit);
      setPurchasePrice(
        +matchPriceObjState[0]?.grossPrice.replace(/,/g, "") -
        (+matchPriceObjState[0]?.grossPrice.replace(/,/g, "") / 100) * 10
      );
      setSellingPrice(matchPriceObjState[0]?.grossPrice);
    } else {
      reset();
    }
  }, [matchPriceObjState]);

  const handleMaterialItemNumber = async (e) => {
    setMatchPriceObjState(filterObjectsByNumber(priceListDataState, e.value));
  };

  const handleMaterialList = (data) => {
    setMaterialList(data?.data?.material);
    dispatch(selectedWorkorder(data?.data));
    toast.success(t("material_added_successfully"));
  };

  const handleMaterialSubmit = async (e) => {
    e.preventDefault();
    const formDataToBeValidated = {
      materialWholesale,
      materialUnit,
      materialQty,
      materialAward: purchasePrice,
      materailVAT: sellingPrice.replace(/,/g, ""),
    };

    const { error } = AddMaterialValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });
      return;
    }

    const material = {
      wholesale: materialWholesale,
      name: materialName?.value,
      number: materialItemNumber?.value,
      unit: materialUnit,
      quantity: materialQty,
      award: materialAward,
      vat: materailVAT,
      invoiceNo: ueInvoiceNo,
      materialCost: ueMaterialCost,
      laborCost: ueLaborCost,
      surcharge: ueSurcharge,
      user: `${userData?.first_name} ${userData?.last_name}`,
      purchasePrice: purchasePrice,
      sellingPrice: sellingPrice.replace(/,/g, ""),
      date: new Date(),
      isExported: addMaterialFromInvoice ? true : false
    };

    const existingMaterialIndex =
      materialList?.length &&
      materialList?.findIndex(
        (m) =>
          m.wholesale === materialWholesale &&
          m.number === materialItemNumber?.value
      );

    if (existingMaterialIndex !== -1 && materialList?.length) {
      handleAlreadyExistingMAterial(materialQty, existingMaterialIndex);
      return;
    }

    if (matchPriceObjState[0]) {
      const data = await createWorkorderAttributes(
        state?.workorderData?._id || selectedWorkorderData?._id,
        material,
        "material",
        invoiceId
      );
      if (data.status) {
        if (invoiceId) {
          setMaterialList(data?.data?.workOrder.material);
          dispatch(selectedWorkorder(data?.data.workOrder));
          dispatch(invoiceData(data?.data.Invoice))
          toast.success(t("material_added_successfully"));
        }
        else {
          handleMaterialList(data);
        }
        reset();
      }
    } else {
      toast.error(t("the_item_number_does_not_match_the_price_list"));
    }
  };

  const receiveDataFromArticle = async (articleData) => {
    const { itemNumber, name, quantity, unit, entryPrice, priceOut } =
      articleData;
    const material = {
      wholesale: materialWholesale,
      name: name,
      number: itemNumber,
      unit: unit,
      quantity,
      purchasePrice: entryPrice,
      sellingPrice: priceOut,
      user: `${userData?.first_name} ${userData?.last_name}`,
      date: new Date(),
      isExported: addMaterialFromInvoice ? true : false
    };
    const existingMaterialIndex = materialList?.findIndex(
      (m) => m.wholesale === materialWholesale && m.number === itemNumber
    );
    if (existingMaterialIndex !== -1 && materialList?.length) {
      handleAlreadyExistingMAterial(quantity, existingMaterialIndex);
      return;
    }

    const data = await createWorkorderAttributes(
      state?.workorderData?._id,
      material,
      "material",
      invoiceId
    );
    if (data.status) {
      if (invoiceId) {
        setMaterialList(data?.data?.workOrder.material);
        dispatch(selectedWorkorder(data?.data.workOrder));
        dispatch(invoiceData(data?.data.Invoice))
      } else {
        handleMaterialList(data);
      }
      toast.success(t("material_added_successfully"));
      reset();
    }
  };


  const handleAlreadyExistingMAterial = async (qty, index, qtyEdit) => {
    let copy = { ...materialList[index] };
    if (!qtyEdit) {
      copy.quantity = Math.floor(qty);
    } else {
      copy.sellingPrice = qty;
    }
    let arrCopy = [...materialList];
    arrCopy[index] = { ...copy };
    setMaterialList([...arrCopy]);
    const data = await updateWorkorderAttributes(
      state?.workorderData?._id,
      copy,
      "material"
    );
    if (data.status) {
      handleMaterialList(data);
      toast.success(t("material_updated_successfully"));
      reset();
    }
  };
  

  const handleMaterialDelete = async (ele) => {
    const confirmed = window.confirm(
      t("are_you_sure_you_want_to_delete_this_material")
    );
    if (confirmed) {
      const list = materialList.filter((m) => m._id == ele._id);
      const data = await deleteWorkorderAttributes(
        state?.workorderData?._id,
        list[0],
        "material"
      );
      if (data.status) {
        handleMaterialList(data);
        reset();
        return toast.success(t("material_deleted_successfully"));
      }
    }
  };
  const getListOfFavorites = async () => {
    const data = await getFavoriteLists(userData);
    if (data?.status) {
      setFavoriteLists(data?.data);
    }
  };

  const formateNumber = (number) => {
    return number?.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).replace(/,/g, ' ')
  }

  const handleCheckboxChange = (id) => {
    const isSelected = checkedFavLists.includes(id);
    if (isSelected) {
      setCheckedFavLists((prevIds) => prevIds.filter((selectedId) => selectedId !== id));
    } else {
      setCheckedFavLists((prevIds) => [...prevIds, id]);
    }
  };

  const handleFavMaterialChange = (id) => {
    const isSelected = favMaterials.includes(id);
    if (isSelected) {
      setFavMaterials((prevIds) => prevIds.filter((selectedId) => selectedId !== id));
    } else {
      setFavMaterials((prevIds) => [...prevIds, id]);
    }
  };

  const handleAddFavMaterials = async (e) => {
    e.preventDefault();
    // setActive(true)
    if (!favMaterials.length) {
      return toast.error("Please select material")
    }
    if (!checkedFavLists.length) {
      return toast.error("Please select favorite list")
    }
    const data = await addFavoriteMaterials(checkedFavLists, userData, favMaterials)
    if (data?.status) {
      toast.success("Favorites added successfully")
      setCheckedFavLists([])
      setFavMaterials([])
    }
    else {
      toast.info("This items already exist in this list")
      setCheckedFavLists([])
      setFavMaterials([])
    }
  }

  const handleMaterialSearchChange = (e, searchField) => {
    if (searchField === "number") {
      setArticleNameSearch("")
      setMaterialItemNumber({})
      const query = e.target.value?.toLowerCase();
      setArticleNumberSearch(query)
      const filteredItems = (favMaterialFromFavList.length ? favMaterialFromFavList : priceListDataState).filter((item) =>
        (item.number?.includes(query))
      );
      setFilteredMaterial(filteredItems);
    } else {
      setArticleNumberSearch("")
      setMaterialName({})
      const query = e.target.value?.toLowerCase();
      setArticleNameSearch(query)
      const filteredItems = (favMaterialFromFavList.length ? favMaterialFromFavList : priceListDataState).filter((item) =>
        (item.name?.toLowerCase().includes(query))
      );
      setFilteredMaterial(filteredItems);
    }
  };

  useEffect(() => {
    const data = selectedWorkorderData;
    if (data) {
      setMaterialList(data.material);
    }
  }, [materialList]);

  useEffect(() => {
    const data = selectedWorkorderData || state?.workorderData;
    if (data) {
      setMaterialList(data.material || []);
    }
    getListOfFavorites();
  }, []);

  useEffect(() => {
    if (favMaterialFromFavList?.length) {
      setFavoriteWholesaleWindow(!favoriteWholesaleWindow)
    }
  }, [favMaterialFromFavList])



  return (
    <>
      <Form>
        <div className="row">
          <div className="col-xl-auto col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("wholesale")}</Form.Label>
              <select
                className="form-select mb-3"
                onChange={(e) => {
                  setMaterialWholesale(e.target.value);
                  handleToggle(e.target.value);
                }}
                value={materialWholesale}>
                <option value={"Wholeseller"} selected>
                  {t("wholesale")}
                </option>
                {[
                  ...WHOLESALE_LIST,
                  ...(favoritsLists
                    ?.filter((f) => f.isPublic)
                    .map((f) => ({ label: f.name, value: f._id })) || []),
                ].map((c, index) => (
                  <option key={index} value={c.value}>
                    {c.label}
                  </option>
                ))}
              </select>
              {favoriteWholesaleWindow && (
                <span className={favoriteWholesaleWindow ? "activeMenu" : ""}>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setFavoriteWholesaleWindow(false);
                    }}>
                    <div className="">
                      {/* {favoriteWholesaleWindow && */}
                      <ArticleMegaMenu
                        selectType="article_Number"
                        setMaterialItemNumber={setMaterialItemNumber}
                        materialWholesale={materialWholesale}
                        priceListDataState={
                          articleNumberSearch
                            ? filteredMaterial
                            : favMaterialFromFavList?.length
                            ? favMaterialFromFavList
                            : priceListDataState
                        }
                        handleMaterialItemNumber={handleMaterialItemNumber}
                        favoritsLists={favoritsLists}
                        checkedFavLists={checkedFavLists}
                        handleCheckboxChange={handleCheckboxChange}
                        handleAddFavMaterials={handleAddFavMaterials}
                        handleFavMaterialChange={handleFavMaterialChange}
                        favMaterials={favMaterials}
                        hideWindow={() => setFavoriteWholesaleWindow(false)}
                      />
                      {/* } */}
                    </div>
                  </OutsideClickHandler>
                </span>
              )}
            </Form.Group>
          </div>

          {materialWholesale !== "Underestreprened" &&
            materialWholesale !== "Free text article" && (
              <>
                <div className="col-xl-auto col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("article_number")}</Form.Label>

                    <span className={isActive ? "activeMenu" : ""}>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setActive(false);
                        }}>
                        <div className="articleNumb" onClick={handleToggle}>
                          {/* <p className="m-0">{materialItemNumber ? materialItemNumber?.value : 'Select...'}</p> */}
                          <input
                            className="form-select"
                            type="text"
                            placeholder="Select..."
                            value={
                              materialItemNumber
                                ? materialItemNumber.value
                                : articleNumberSearch
                            }
                            onChange={(e) =>
                              handleMaterialSearchChange(e, "number")
                            }
                          />
                        </div>
                        <div className="">
                          {isActive && (
                            <ArticleMegaMenu
                              selectType="article_Number"
                              setMaterialItemNumber={setMaterialItemNumber}
                              dataRender=""
                              priceListDataState={
                                articleNumberSearch
                                  ? filteredMaterial
                                  : favMaterialFromFavList?.length
                                  ? favMaterialFromFavList
                                  : priceListDataState
                              }
                              handleMaterialItemNumber={
                                handleMaterialItemNumber
                              }
                              favoritsLists={favoritsLists}
                              materialWholesale={materialWholesale}
                              checkedFavLists={checkedFavLists}
                              handleCheckboxChange={handleCheckboxChange}
                              handleAddFavMaterials={handleAddFavMaterials}
                              handleFavMaterialChange={handleFavMaterialChange}
                              favMaterials={favMaterials}
                              hideWindow={() => setActive(false)}
                            />
                          )}
                        </div>
                      </OutsideClickHandler>
                    </span>
                  </Form.Group>
                </div>
                <div className="col-xl-auto col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("article_name")}</Form.Label>

                    <span className={articleNameBox ? "activeMenu" : ""}>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setArticleNamebox(false);
                        }}>
                        <div
                          className="articleNumb"
                          onClick={handleNameBoxToggle}>
                          <input
                            className="form-select"
                            type="text"
                            placeholder="Select..."
                            value={
                              materialName
                                ? materialName.value
                                : articleNameSearch
                            }
                            onChange={(e) =>
                              handleMaterialSearchChange(e, "name")
                            }
                          />
                        </div>
                        <div className="">
                          {articleNameBox && (
                            <ArticleMegaMenu
                              priceListDataState={
                                articleNameSearch
                                  ? filteredMaterial
                                  : favMaterialFromFavList?.length
                                  ? favMaterialFromFavList
                                  : priceListDataState
                              }
                              handleMaterialItemNumber={
                                handleMaterialItemNumber
                              }
                              dataRender=""
                              favoritsLists={favoritsLists}
                              checkedFavLists={checkedFavLists}
                              handleCheckboxChange={handleCheckboxChange}
                              handleAddFavMaterials={handleAddFavMaterials}
                              handleFavMaterialChange={handleFavMaterialChange}
                              favMaterials={favMaterials}
                              materialWholesale={materialWholesale}
                              hideWindow={() => setArticleNamebox(false)}
                            />
                          )}
                        </div>
                      </OutsideClickHandler>
                    </span>
                  </Form.Group>
                </div>
                <div className="col-xl-auto col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("quantity")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        value={materialQty}
                        onChange={(e) => setMaterialQty(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                {materialWholesale !== "Underestreprened" &&
                  materialWholesale !== "Free text article" && (
                    <div
                      className="col-xl-auto col-lg-4"
                      style={{ marginTop: "2rem" }}>
                      <Button
                        className="simple-btn mx-auto"
                        onClick={(e) => handleMaterialSubmit(e)}>
                        {t("add_material")}
                      </Button>
                    </div>
                  )}
              </>
            )}

          {(materialWholesale === "Free text article" ||
            materialWholesale === "Underestreprened") && (
            <FreeTextArticlesTab
              workorder={state?.workorderData}
              sendDataToMaterial={receiveDataFromArticle}
              wholesale={materialWholesale}
            />
          )}
        </div>
      </Form>
      {!(addMaterialFromInvoice || addMaterialFromFavorites) && (
        <>
          <div className="wrappFavorite">
            <div className="table-responsive material-table alternative-colors mb-5">
              <table className="text-start">
                <thead>
                  <tr>
                    <th>{t("wholesale")}</th>
                    <th className="col-w-150px">{t("article_number")}</th>
                    <th className="col-w-450px">{t("article_name")}</th>
                    <th className="col-w-100px">{t("quantity")}</th>
                    <th className="col-w-100px">{t("unit")}</th>
                    <th className="col-w-120px">{t("purcahse_price")}</th>
                    <th className="col-w-120px">{t("selling_price")}</th>
                    <th>{t("Added by")}</th>
                    <th>{t("date")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {materialList?.map((ele, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{ele.wholesale}</td>
                        <td>{ele.number}</td>
                        <td className="col-w-450px">{ele.name}</td>
                        {ele.isExported ? (
                          <td className="col-w-100px">{ele.quantity}</td>
                        ) : (
                          <td className="col-w-100px">
                            <MaterialQuantityColumnsRow
                              row={ele}
                              materialList={materialList}
                              setMaterialList={handleAlreadyExistingMAterial}
                            />
                          </td>
                        )}
                        <td className="col-w-100px">{ele.unit}</td>
                        <td className="col-w-120px">
                          {formateNumber(ele.purchasePrice)}
                        </td>

                        {ele.isExported ||
                        selectedWorkorderData?.status.toLowerCase() !==
                          "new offer" ? (
                          <td className="col-w-150px">
                            {formateNumber(ele.sellingPrice)}
                          </td>
                        ) : (
                          <td className="col-w-150px">
                            <MaterialPriceColumnsRow
                              row={ele}
                              materialList={materialList}
                              setMaterialList={handleAlreadyExistingMAterial}
                              formateNumber={formateNumber}
                            />
                          </td>
                        )}
                        <td title={ele.user}>
                          {ele.user
                            ?.split(" ")
                            .map((word) => word.charAt(0))
                            .join(".")}
                        </td>
                        <td>{moment(ele.date).format("YYYY-MM-DD  HH:mm")}</td>
                        <td>
                          {ele.isExported ? (
                            "$"
                          ) : (
                            <button
                              className="simple-btn table-del-btn-min-w-20px"
                              type="submit"
                              onClick={() => handleMaterialDelete(ele)}>
                              <i className="fa fa-trash m-2 fs-5"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <CommonButton
            setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
            exportInvoice={exportInvoice}
            isOffer={isOffer}
          />
        </>
      )}
      <PdfTypeModal
        isPdfTypeModalVisible={isPdfTypeModalVisible}
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
      />
    </>
  );
};

export default MaterialTab;
